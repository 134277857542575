﻿#applied-products {
	min-width: 25%;
	top: 0;
	z-index: 3000;
	width: 50%;
	display: flex;
	flex-direction: column;
}

#applied-products-block h4 {
	margin: 0 0 2vmin;
    color: #ee2e24;
	font-family: 'FuturaBT', Arial, serif;
    font-size: 2.4vmin;
	font-weight: 500;
}

#applied-products.open {
	transform: translateX(0);
}

#applied-products .close {
	padding: 6px 10px;
	color: white;
	border: none;
	background-color: rgb(35, 93, 123);
	font-size: 1.2em;
	position: absolute;
	top: 0;
	right: 100%;
}

#applied-products .close:focus {
	outline: none;
}

#applied-list {
	flex-grow: 1;
	overflow-x: hidden;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;

	> ul {
		margin: 0;
		padding: 0;
	}
}

#applied-list li {
  padding: 2% 0;
  list-style: none;
}

#applied-list li h5{
    color: #948e90;
    font-size: 2.4vmin;
    font-weight: bold;
    margin: 0;

    /* Overwrite Fiberon css. */
    padding: 0;
    border-bottom: none;
    text-align: left;
    line-height: inherit;
}

#applied-list li h6{
    color: #948e90;
    font-size: 1.6vmin;
	font-weight: 400;
    margin: 0;
	text-transform: uppercase;
}

#applied-list li:first-child {
  padding-top: 0;
}

#applied-list li > div {
  font-size: 1.7vmin;
}

#applied-list li img {
  width: 100px;
  display: inline-block;
  vertical-align: top;
  flex-basis: 100px;
}

#applied-list li .surface-name {
  font-weight: bold;
}

#appliedp-list{
    background-color:#ffffff;
    height:100%;
    width:100%;
    position:relative;
}

.product-info {
    display:flex;
    flex-flow:row wrap;
    justify-content:space-between;

	> div {
		width: 50%;
	}
}

.product-image {
	width: 100%;
	padding-top: 50%;
	background-repeat: no-repeat;
	background-position: center;

	&.covered {
		background-size: cover;	
	}

	&.contained {
		background-size: contain;
	}
}

.product-specifics {
	padding: 0 4vmin;
}

.product-specifics p {
    color: #161616;
    margin: 0;
    font-size: 1.6vmin;
}

.product-specifics h1 {
	margin-top: 2vmin;
    color:#ee2e24;
    font-size:5vmin;
    padding:0;
	line-height: 1;

	sup {
		font-size: 55%;
		vertical-align: super;
		position: relative;
		top: 0;
		left: 0.1em;

		&.dollar-sign {
			font-size: 45%;
			top: 0.05em;
			left: 0;
		}
	}

	sub {
		font-size: 30%;
		vertical-align: sub;
		position: relative;
		bottom: 0.4em;
		left: -1.6em;
	}
}

#get-a-quote {
    width: 50%;
	padding: 0 4vmin;
	display: flex;
	flex-direction: column;
}

#appliedp-list{
	padding-top: 2vmin;
    display:flex;
    flex-flow:row wrap;
    justify-content: center;
    overflow-y: auto;
}



.mktoForm .mktoFormCol {
	width: 100% !important;
	float: none !important;

}

.mktoForm .mktoFieldWrap, .mktoForm .mktoLabel, .mktoForm .mktoField, .mktoForm .mktoAsterix {
    float: none !important;
}

.mktoForm .mktoField {
	width: 100% !important;
	max-width: 540px !important;
	display: block;
}

.mktoForm .mktoLabel {
	width: 100% !important;
	font-weight: normal !important;
}

.mktoForm .mktoLabel, .mktoForm .mktoAsterix  {
	padding: 0 !important;
	display: inline-block !important;
}

.mktoForm .mktoAsterix  {
	margin-right: 3px;
}

.mktoForm .mktoOffset, .mktoForm .mktoGutter {
	display: none !important;
}

.mktoForm .mktoFieldDescriptor {
	margin: 0 0 2vmin !important;
}

.mktoForm .mktoButtonWrap {
	margin-left: 0 !important;
}

.mktoForm .mktoError {
	position: static !important;
}

.mktoForm .mktoErrorArrowWrap {
	display: none !important;
}

.mktoForm .mktoError .mktoErrorMsg {
    max-width: none !important;
    margin: 4px 0 0 !important;
	padding: 0 !important;
    color: #ee2e24 !important;
	background: transparent !important;
	border: none !important;
	text-shadow: none !important;
	box-shadow: none !important;
}

.mktoForm .mktoErrorMsg .mktoErrorDetail {
	display: inline-block !important;
}

.mktoForm .mktoButtonWrap button:after {
	content: url('/img/btn-arrow.png');
	margin-left: 10vmin;
}

.mktoButtonRow > *:after {
    display: none;
}

#quote-wrapper {
	flex-grow: 1;
	overflow-x: hidden;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;


}

.quote-form {
	width: 100% !important;
	padding: 0 !important;
    font-size: 1.4vmin !important;
}

.quote-form input, .quote-form select, .quote-form textarea {
	height: auto !important;
	padding: 10px 12px !important;
	font-size: 1.3em !important;
	line-height: 1 !important;
	border: 1px solid #858789 !important;
}

.quote-form select[multiple] {
	padding: 10px 0 !important;

	option {
		padding: 0 12px !important;
	}
}

.single-form input, .quote-form input {
    width: 100%;
	max-width: 540px;
}

.single-form {
    margin: 0 0 2vmin;
}

.multi-form input {
    width: 30%;
}

.multi-form {
    width: 100%;
	max-width: 540px;
	margin: 0 0 2vmin;
    display:flex;
    flex-flow:row no-wrap;
    justify-content:space-between;

	select {
		padding: 9px 12px !important;
	}
}

#city-form input{
    width:100%;
}

#state-form {
	padding: 0 2vmin;
}

#zip-form input{
    width:100%;
}

span.red {
    color:#ee2e24;
}

.validation-error {
	margin-top: 4px;
	color: #ee2e24;
}

#quote-submit, .mktoForm .mktoButtonWrap {
    margin-top: 2vmin;
}

a{
    color:#ee2e24;
}

#applied-products-block{
    width: 100%;
	height: 100%;
	padding: 5vmin 12vmin 8vmin;
	background-color: white;
    position: fixed;
	top: 0;
	left: 0;
	z-index: 2000;
	display: none;
	-webkit-tap-highlight-color: transparent;
}

@media all and (max-width: 1000px) {
	#applied-products-block {
		overflow-x: hidden;
		overflow-y: auto;
		-webkit-overflow-scrolling: touch;
	}

	#appliedp-list {
		height: auto;
		flex-flow: column-reverse nowrap;

		> div {
			width: 100%;
		}
	}

	#applied-products {
		margin-top: 6vmin;
	}

	#get-a-quote {
		padding: 0;
	}

	#quote-wrapper {
		overflow: hidden;
	}

	.single-form input {
		max-width: none;
	}
}

@include mobile-breakpoint() {
	.quote-form {
		font-size: 1em !important;
	}

	#applied-products-block h4 {
		margin-bottom: 5vmin;
		font-size: 1.5em;
	}

	#applied-products {
		display: block;
	}

	#applied-list {
		overflow: hidden;

		li {
			padding: 1.5vmin 0;
		}

		li h5 {
			font-size: 1.2em;
		}
	}

	.product-specifics {
		margin: 2vmin 0;
		padding: 0;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;

		> div {
			margin-bottom: 1vmin;
		}

		h6, p {
			font-size: 1em !important;
		}

		h1 {
			margin-top: 0;
			font-size: 8vmin;
		}
	}

	.product-info {
		font-size: 1em !important;
		flex-flow: column nowrap;

		> div {
			width: 100%;
		}
	}
}