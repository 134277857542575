/* ==========================================================================
    GLOBAL
   ========================================================================== */

.cham {
    width: 100%;
    height: auto;
    margin: 0 auto;
    overflow: hidden;
    box-sizing: border-box;
}

#MAIN {
    width: 100%;
    height: 100%;
}

.visualizer { /* Override Fiberon css. */
    text-align: left;
    padding-bottom: 0;
}

h1, h2 {
    padding: 0 0 0 1.5%;
    margin: 0;
    font-family: 'ProximaNova-Regular', Arial, serif;
    font-weight: normal;
    color: #242424;
    line-height: inherit;
}

h1 {
    padding: 5% 0 0 1.5%;
    font-size: 5.9vh;
}

h2 {
    font-size: 3.0vh;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
}

h3, h4 {
    font-weight: bold;
    line-height: inherit;
}

p {
    font-family: 'ProximaNova-Regular', Arial, serif;
    line-height: inherit;
}

form {
    /* Overwrite Fiberon css */
    margin-bottom: 0;
}

header {
    height: 13%;
    width: 100%;
    padding-top: 0;
    background-color: white;
}

#title-center {
    margin: 0 auto;
    padding: 1.5vmin 0 1.3vmin;
    width: 100%;
    max-width: 1024px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

#title-center h1 {
    color: #ffffff;
    font-family: 'FuturaT', Arial, serif;
    font-weight: bold;
    padding: 2% 0 0 0;
    text-align: center;
}

main {
    height: 87%;
    position: relative;
    margin: 0 auto;
    width: 100%;
}

main section {
    width: 100%;
    height: 100%;
}

.hidden {
    display: none;
}

.invisible {
    visibility: hidden;
}

.red-button, .mktoForm .mktoButtonWrap {
    margin-bottom: 2vmin;
    position: relative;
    display: inline-block;

    button {
        max-width: 100%;
        padding: 2.6vmin 3vmin;
        background-color: #ee2e24;
        color: #ffffff;
        font-size: 1.2em;
        font-family: 'FuturaT', Arial, serif;
        font-weight: bold;
        letter-spacing: 1px;
        line-height: 1;
        text-transform: uppercase;
        border: none;
        cursor: pointer;
        /* Firefox - flexbox bugs when using directly on button elements. */
        > span {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        &:hover, &:focus {
            background-color: #FF3832;
            outline: none;
        }

        img {
            margin-left: 10vmin;
        }
    }

    &:after {
        max-width: 100%;
        content: url('/img/btn-shadow.png');
        position: absolute;
        left: 0;
        top: 100%;
        overflow: hidden;
    }
}

@include mobile-breakpoint() {
    .cham {
        height: auto;
    }

    #MAIN {
        display: flex;
        flex-direction: column;
    }

    header {
        flex-shrink: 0;
    }

    main {
        height: auto;
        position: static;
        flex-grow: 1;
        display: flex;
        flex-direction: column;

        section {
            position: static;
        }
    }

    .red-button button, .mktoForm .mktoButtonWrap button {
        padding: 4vmin 8vmin;
    }

    header {
        height: auto;
    }

    h1 {
        font-size: 4.9vh;
    }
}


/*CLADDING ADJUSTMENTS*/

#roomscene-data li[data-index="10"] {
    display: none;
}

#roomscene-data li[data-index="11"] {
    display: none;
}

p.tab-link[data-tab="1"] {
    display: none;
}

a#get-samples-cladding {
    display: none;
}

div#estimate-cladding {
    display: none;
}