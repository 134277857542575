.modal {
  background-color: #FFF;
  z-index: 998;
  display: none;
  box-shadow: 0px 6px 15px 0px rgba(50, 50, 50, 0.5);
  background-repeat: no-repeat;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  padding: 2%;
  box-sizing: border-box;
}

.modal-headline {
  /* width: 525px; */
  /* margin-top: 30px; */
  /* margin-left: -24px; */
  text-align: center;
  /* padding-left: 82px; */
  /* color: #f47604; */
  font-size: 2vmin;
  /* font-family: 'AvenirLTStd-Black'; */
  /* text-transform: uppercase; */
  font-family: 'CenturyGothic-Bold';
  color: rgb(35,93,123);
}

.modal-close {
  /* height: 25px; */
  /* width: 24px; */
  float: right;
  /* margin-top: -19px; */
  color: rgb(35,93,123);
  margin-right: 0px;
  cursor: pointer;
  /* background-color:  rgb(35,93,123); */
  font-family: 'CenturyGothic';
  /* line-height: 26px; */
  position: absolute;
  right: 3%;
  top: 2%;
  font-size: 3vmin;
}

.modal-box {
  width: 47vmin;
  height: 32vmin;
  margin: 2vmin auto;
  text-align: center;
  font-family: 'CenturyGothic';
}

.upload-box {
  width: 85%;
  height: 60%;
  margin: 0 auto;
  background-color: #EAEAEA;
  /* border-radius: 3px; */
  border: dashed 2px #D6D6D6;
  color: #575757;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 300;
  /* display: none; */
  padding: 20%;
  box-sizing: border-box;
}

.upload-box p {

  font-size: 1.5vmin;
}

.upload-box input[type="file"] {
  display: none;
}

.modal-pic {
  /* width: 450px; */
  /* height: 280px; */
  margin: 0 auto;
  background-color: #EAEAEA;
  border-radius: 3px;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 200;
  /* display: none; */
  clear: both;
  overflow: hidden;
  width: 85%;
  height: 60%;
}


.trash {
  /* height: 42px; */
  /* width: 50px; */
  background-color: #646464;
  color: #FFF;
  /* float: right; */
  font-size: 4vmin;
  /* padding-top: 8px; */
  cursor: pointer;
  border-top-right-radius: 3px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 4;
  padding: 1vmin;
}

.modal-pic #imgToUpload {
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
}

.modal-label {
  float: left;
  font-size: 16px;
  font-weight: bold;
  color: #575757;
  margin: 5px 0 0 55px;
  /* font-weight: 900; */
  font-family: 'CenturyGothic';
}

.modal-input {
  padding: 10px;
  border: 1px solid #B1B1B1;
  border-radius: 5px;
  border-image: none;
  font-size: 20px;
  color: #323031;
  box-shadow: 0px 0px 0px 0px rgba(42, 42, 42, 0.75);
  margin: 10px 0 0 50px;
  width: 440px;
  float: left;
}

.project-button {
  /* cursor: pointer; */
  /* background-color: #f01e00; */
  -moz-border-radius: 5px;
  /* border-radius: 5px; */
  /* border: 1px solid #fff; */
  /* cursor: pointer; */
  /* color: #fff; */
  /* box-sizing: border-box; */
  display: block;
  text-align: center;
  /* line-height: 32px; */
  /* width: 235px; */
  /* margin: 26px auto 0 auto; */
  /* font-family: 'CenturyGothic'; */
  /* letter-spacing: 0.05em; */
  /* height: 52px; */
  font-size: 20px;
}


.project-button i {
  margin-left: 5px;
}


.btn-upload {
}

.uploaderBTN {
  background-color: #fff;
  color: #fff;
  /* border: 1px solid #f5980c; */
  text-align: center;
  cursor: pointer;
  -webkit-border-radius: 9px;
  -moz-border-radius: 9px;
  border-radius: 1px;
  box-sizing: border-box;
  padding: 1.5vmin;
  font-weight: bold;
  background-color: rgb(35,93,123);
  font-family: 'CenturyGothic';
  letter-spacing: 0.05em;
  text-transform: uppercase;
  font-size: 1.5vmin;
}



/*overlay*/

#overlay {
  position: fixed;
  top: 0;
  left: 0;
  background-color: #000;
  width: 100%;
  height: 100%;
  display: none;
  position: absolute;
  z-index: 997;
}


#closemodal {
  float: right;
  cursor: pointer;
  /* letter-spacing: 3px; */
  margin-top: 15px;
  font-size: 1.25vh;
  margin-right: 16px;
  color: #353334;
  font-weight: 900;
}


#closemodal i {
  color: rgb(35,93,123);
  margin-right: 5px;
}


#upload-container {
  width: 75%;
  height: 60%;
  /* margin-left: auto; */
  /* margin-right: auto; */
  position: absolute;
  background-color: #ffffff;
  display: block;
  display: none;
  top: 0;
  left: 0;
  margin: auto;
  right: 0;
  bottom: 0;
  max-width: 1155px;
}

.upload-left-head {
  font-size: 2.75vmin;
  color: rgb(35,93,123);
  float: left;
  font-family: 'CenturyGothic-Bold';
  text-transform: uppercase;
}

.upload-box-left {
  /* height: 100%; */
  width: 36%;
  /* padding: 75px 0 0 75px; */
  background-color: #fff;
  float: left;
  font-family: 'CenturyGothic';
  padding: 5% 0% 5% 5%;
  box-sizing: border-box;
  /* background-color: #eee; */
}

.upload-box-right {
  /* height: 100%; */
  width: 64%;
  /* margin-left: 1%; */
  float: right;
  box-sizing: border-box;
  /* background-color: #ff0; */
  padding-left: 7%;
}

.upload-left-text {
  font-size: 1.35vmin;
  font-weight: bold;
  float: left;
  /* padding-right: 16px; */
  margin-top: 20px;
  line-height: 2vh;
  color: rgb(88,88,88);
}

.upload-left-text i {
  color: rgb(35,93,123);
  margin-left: 2%;
  margin-right: 1%;
}

.upload-button-box {
  /* width: 280px; */
  /* padding: 65px 0 0 0; */
  float: left;
  /* background-color: #ff0; */
  padding-top: 10%;
  width: 100%;
  text-align: center;
}

.photo-good {
  /* height: 64%; */
  width: 40%;
  margin: 12% 3% 0 0;
  float: left;
  /* background-image: url(/img/good.jpg); */
  position: relative;
  box-sizing: border-box;
  background-repeat: no-repeat;
}

.photo-good img {
  width: 100%;
  height: 100%;
  /* background-repeat: no-repeat; */
}


.photo-good.bad {
  /* background-image: url(/img/bad.jpg); */
}

.photo-text {
  /* height: 100px; */
  width: 85%;
  margin-top: 2%;
  float: left;
  font-family: 'CenturyGothic';
}


.star {
  color: #e11c00;
  float: left;
  width: 10px;
  margin-right: 10px;
}

.star-text {
  float: right;
  /* width: 475px; */
  font-size: 1.2vh;
  font-weight: bold;
  line-height: 2vh;
  font-family: 'CenturyGothic';
  color: rgb(88,88,88);
}


.star-text i {
  color: rgb(35,93,123);
  margin-right: 1%;
}

.photo-box-bar {
  /* height: 45%; */
  width: 100%;
  /*background-image: url(/img/black.png);*/
  background-color: rgba(0, 0, 0, 0.78);
  color: #fff;
  font-size: 2vh;
  font-weight: bold;
  /* padding: 18px 0 0 15px; */
  box-sizing: border-box;
  padding: 4%;
  position: absolute;
  z-index: 5000;
  top: 0;
}


.btn-upload {
  font-family: 'CenturyGothic';
  color: #ffffff;
  font-size: 2vmin;
  padding: 3%;
  background-color: rgb(88,88,88);
  cursor: pointer;
  box-sizing: border-box;
  width: 100%;
  margin: 0 auto;
  display: block;
}
