/* ----------------------------------------------------------------
Visualizer
-----------------------------------------------------------------*/


.visualizer-wrapper {
    display: none;
    width: 100%;
    height: 100%;
}

main .visualizer-wrapper {
    max-width: 1280px;
    margin: 0 auto;
}

header {
    position: relative;
}

header .landing {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

header .visualizer-wrapper {
    width: 100%;
    padding: 6.5vmin 0 2.5vmin;
}

header .landing {
    height: 100%;
    background: url(/img/header.jpg) repeat-x;
}

header .visualizer {
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

header nav.visualizer div {
    margin-top: 0.8vmin;
    display: inline-block;
    font-size: 1.5vmin;
    line-height: 3vmin;
    padding: 0 1% 0% 1%;
    text-align: center;
    cursor: pointer;
    box-sizing: border-box;
}

header nav.visualizer div:last-child {
    padding: 0 3% 0 1%;
}

header nav.visualizer i.fa {
    display: block;
    font-size: 2.5vh;
    cursor: pointer;
}

section.visualizer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row-reverse;
}

.visualizer-pane {
    height: 100%;
    position: relative;
    flex-grow: 1;
    overflow: hidden;
    background-color: #ebebeb;
}

#visualizer {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
}

#side-menu {
    width: 25%;
    max-width: 300px;
    display: flex;
    flex-flow: column wrap;
    justify-content: flex-start;
    -webkit-tap-highlight-color: transparent;
}

#estimate, #sign-in, #share, #get-samples, #get-samples-cladding, #estimate-cladding {
    width: 159px;
    background-color: #ebebeb;
    text-align: center;
    margin: 0 1vmin;
    padding: 1vmin 0;
    font-family: Arial, serif;
    font-weight: bold;
    color: #858789;
    font-size: 1.3vmin;
    text-decoration: none;
    text-transform: uppercase;
    cursor: pointer;
    border-top: 2px solid transparent;
}

#header-bar {
    width: 100%;
    height: 100%;
    border-bottom: 1px solid #ebebeb;
}

#header-stack {
    display: none;
}

#header-nav {
    height: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: flex-end;
}

div#share.active {
    border-top: 2px solid #ee2e24;
    background-color: #ffffff;
    border-right: 1px solid #ebebeb;
    border-left: 1px solid #ebebeb;
}

#surface-menu {
    width: 100%;
    background-color: white;
    position: relative;
    overflow: hidden;
    z-index: 10;
}

#surface-menu ul {
    list-style-type: none;
    padding: 0;
    color: #948e90;
    font-family: 'FuturaBT', Arial, serif;
    font-weight: 500;
    font-size: 2.5vmin;
    margin-bottom: 4vmin;
}

#surface-menu li {
    margin-bottom: 1vmin;
    padding: 0 0 0 3vmin;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    &:hover, &.active {
        color: #EE2E24;
    }

    i {
        padding-right: 4vmin;
        font-size: 0.6em;
        display: none;
    }

    &.active i {
        display: block;
    }
}

#home {
    width: 92%;
    background-color: #ee2e24;
    /*background-color: #000000;*/
    color: #ffffff;
    text-align: center;
    margin-top: 1.2vmin;
    padding: 2vmin 3vmin;
    font-family: 'FuturaT', Arial, serif;
    font-weight: bold;
    letter-spacing: 1px;
    font-size: 2vmin;
    /*border-bottom: 1px solid #F2625B;*/
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    align-content: center;
    cursor: pointer;
    position: relative;

    &:hover, &:focus {
        background-color: #FF3832;
    }
}


#home:after {
    max-width: 100%;
    content: url(/img/btn-shadow.png);
    position: absolute;
    left: 0;
    top: 100%;
    overflow: hidden;
}


#home img {
    width: 3.2vmin;
    margin-right: 4vmin;
}

div#save, div#print, div#undo-redo, div#zoom {
    color: #ee2e24;
    font-family: 'FuturaT', Arial, serif;
    font-weight: bold;
    font-size: 1.6vmin;
    padding: 0.8vmin 0 0.8vmin 3vmin;

    img, span {
        display: inline-block;
        vertical-align: middle;
    }

    > img:first-child {
        margin-right: 6px;
    }

    > img:last-child {
        margin-left: 6px;
    }
}

div#save img, div#save span, div#print img, div#print span,div#undo-redo, div#zoom{
  cursor: pointer;
}

#undo-redo {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

#undo, #redo {
    padding: 0.8vmin 0;
}

#undo img {
    margin-right: 6px;
}

#redo img {
    margin-left: 6px;
}

div#save {
}

#product-menus {
    margin: 0;
    padding: 0;
    list-style: none;
}

.product-menu {
    height: 100%;
    width: 50%;
    max-width: 560px;
    padding: 1vmin 2vmin;
    background-color: #ffffff;
    position: absolute;
    left: 0;
    z-index: 101;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    transform: translateX(-100%);
    transition: .4s transform ease-out;
    will-change: transform;
    z-index: 1500;
    -webkit-tap-highlight-color: transparent;

    &.active {
        transform: none;
    }

    .menu-header {
        padding: 0;
        clear: both;
        display: none;
    }

    .menu-content {
        width: 100%;
    }
}

.no-products {
    clear: both;
}

.deck-filter {
    width: 74%;
    margin: 1.6vmin 0 1.2vmin;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    float: left;
    color: #161616;

    p {
        font-size: 1.6vmin !important;
    }
}

.deck-filter a {
    color: #948e90;
    cursor: pointer;
}

.deck-filter p {
    margin: 0 0 0.4vmin;
    font-size: 1.5vmin;
    padding-right: 2vmin;

    &:last-child {
        padding-right: 0;
    }
}

.deck-filter p.active a {
    color: #ee2e24;
    cursor: default;
}

.decking-options li.active, .decking-options-colorf li.active {
    cursor: default;
}

.product.active .rect-thumb:before,
.product.active .rect-thumb-small:before {
    content: '';
    width: 100%;
    height: 100%;
    border: 2px solid #ee2e24;
    position: absolute;
    top: 0;
    left: 0;
}

.close {
    font-size: 1em;
    float: right;
    cursor: pointer;
    padding-right: 25px;

    img {
        width: 2.8vmin;
        height: 100%;
        margin-left: 8px;
    }

    span, img {
        display: inline-block;
        vertical-align: middle;
    }
}

.tab {
    display: none;

    &.active {
        display: block;
    }
}

.product {
    cursor: pointer;
}

.deck-selection select {
    width: 100%;
    margin-bottom: 2vmin;
    font-family: 'ProximaNova-Regular', Arial, serif;
    font-size: 1.7vmin;
    padding: 0.7vmin 0.5vmin;
    cursor: pointer;
}

.deck-selection p {
    font-size: 1.5vmin;
}

ul.decking-options {
    list-style-type: none;
    padding: 0;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
}

.decking-options li {
    width: 47%;
    margin-bottom: 3vmin;
}

.rect-thumb {
    width: 100%;
    padding-bottom: 55%; /* Percentage padding based on width, so this makes height a proportion of the width. */
    position: relative;
}

.rect-thumb-small {
    width: 100%;
    padding-bottom: 50%; /* Percentage padding based on width, so this makes height a proportion of the width. */
    position: relative;
}

.rect-thumb, .rect-thumb-small {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.decking-options li p {
    text-align: center;
    margin: .5vmin 0 0;
}

.rect-thumb div.zoomin, .rect-thumb-small div.zoomin {
    float: right;
    padding: 0.5vmin 0.8vmin;
    color: #ffffff;
    position: relative;
    cursor: pointer;
    z-index: 2;
}

.color-option {
    padding-bottom: 1vmin;
    border-bottom: 1px solid transparent;

    &.active {
        border-color: #ee2e24;

        > div {
            cursor: default;
        }
    }

    > div {
        width: 4vmin;
        height: 4vmin;
        cursor: pointer;
        border-radius: 50%;
    }

    &.all > div {
        border: 1px solid #AAAAAA;
        line-height: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: content-box;
    }
}

.deck-colors {
    margin-bottom: 1vmin;
}

div#deck-color-family {
    clear: both;
}

#deck-color-family ul {
    list-style-type: none;
    padding: 0;
}

#deck-color-family ul {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    margin: 0;
}

#deck-color-family li {
    margin: 0 1.5vmin 1vmin 0;
}

.deck-selection h5 {
    color: #858789;
    margin: .5vmin 0;
    font-size: 1.0em;
    font-weight: 400;
    text-transform: uppercase;
    /* Override Fiberon css */
    border-bottom: none;
    padding: 0;
    text-align: left;
}

.deck-styles .product-group.single-shown h5 {
    display: none;
}

.deck-colors {
    .product-group {
        margin-left: -3%;
    }

    h5 {
        margin-left: 3%;
    }
}

.decking-options-colorf {
    display: flex;
    flex-flow: row wrap;
    list-style-type: none;
    padding: 0;
    justify-content: flex-start;
    margin: 0;
}

.decking-options-colorf li {
    width: 27%;
    margin: 0 3%;
    padding-bottom: 0.5vmin;
    text-align: center;
}

.decking-options-colorf p {
    margin: 0;
}

.decking-type-info {
    h4 {
        margin: 0 0 0.3vmin;
        font-size: 1.7vmin;
    }

    p {
        margin: 0 0 3vmin;
    }
}

.wall-type-info {
    h4 {
        margin: 2vmin 0 0.3vmin;
        font-size: 1.7vmin;
    }

    p {
        margin: 0;
    }
    &.hide{
      display:none;
    }
}

.railing-options {
    padding: 0 0 2vmin;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    list-style-type: none;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin: 0;
}

.railing-selection .zoomin {
    padding: 0.8vmin;
    color: black;
    font-size: 2.5vmin;
    line-height: 1;
    position: absolute;
    bottom: 0;
    right: 0;
}

.railing-options li {
    width: 48%;
}

.railing-options li p {
    text-align: left;
    padding-top: 7px;
}

.railing-thumb, .lighting-thumb {
    width: 100%;
    padding-bottom: 60%;
    position: relative;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.railing-color, .light-color {
    padding: 0;
    list-style-type: none;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
}

.railing-color li, .light-color li {
    width: auto;
    margin: 0 2vmin 1vmin;
    padding-bottom: 1vmin;
    border-bottom: 1px solid transparent;

    &.active {
        border-color: #ee2e24;

        > div {
            cursor: default;
        }
    }

    > div {
        height: 5vmin;
        width: 5vmin;
        border-radius: 50%;
        cursor: pointer;
    }
}

.railing-additional .railing-color, .light-color {
    justify-content: flex-start;

    li {
        margin: 0 1vmin 1vmin;
    }
}

.railing-selection, .lighting-selection {
    h3, h5 {
        margin: 1.3vmin 0 1vmin;
        color: #858789;
        font-size: 1em;
        font-weight: 400;
        text-transform: uppercase;
        /* Override Fiberon css */
        border-bottom: none;
        padding: 0;
        text-align: left;
    }
}

.railing-selection p {
    font-size: 1.5vmin;
}

.rail-info {
    h4 {
        margin: 0;
        font-size: 1.7vmin;
    }

    p {
        margin: 0.3vmin 0 2vmin;
    }
}

#railing-next {
    background-color: #ee2e24;
    color: #ffffff;
    margin-bottom: 1vmin;
    padding: 0.5vmin 3vmin;
    cursor: pointer;
    text-align: center;
    text-transform: uppercase;
    display: inline-block;
}

.railing-steps {
    margin: 1.6vmin 0 1.2vmin;
    font-size: 1.6vmin;
}

.railing-steps p {
    margin: 0 0 0.4vmin .2vmin;
    padding: .6vmin 2vmin .3vmin;
    font-size: 1.7vmin;
    color: #fff;
    display: inline-block;
    cursor: pointer;
    background-color: #948e90;


    &.active {
        background-color: #ee2e24;
        color: #ffffff;
        cursor: default;
    }
}

.rail-step {
    clear: both;
    display: none;

    &.active {
        display: block;
    }

    .centered {
        text-align: center;
    }
}

.railing-type-holder {
    width: 40%;
}

.railing-additional {
    display: none;

    &.active {
        display: block;
    }
}

#step-two li.rail-color-options2 {
    width: 60%;
}

#step-two ul {
    list-style-type: none;
    padding: 0;
    display: flex;
    flex-flow: row wrap;
    margin: 0;

    p {
        margin: 2.4vmin 0;
        text-align: center;
    }
}

#step-two h5 {
    margin: 1vmin 0;
}

.railing-additional > ul > li, .lighting-selection > ul > li {
    padding: 0 1vmin;
}

.railing-color2 li {
    background-color: #000000;
    border: solid 1px #000000;
    height: 5vmin;
    width: 5vmin;
    border-radius: 30px;
    cursor: pointer;
    margin: 0 1vmin;
}

.railing-color2 {
    display: flex;
    flex-flow: row wrap;
    list-style-type: none;
    padding: 0;
    justify-content: flex-start;
}

.light-type-holder div.zoomin {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    height: 100%;
    align-content: flex-end;
    padding: 0 0.8vmin 0.8vmin 0px;
    color: #000000;
    font-size: 2.5vmin;
    position: absolute;
    bottom: 0;
    right: 0;
}

.lighting-selection p {
    margin: 0;
    padding: 0.5vmin 0 0.5vmin;
    clear: both;
    font-size: 1.5vmin;
    text-align: center;
}

.light-type-holder {
    width: 40%;
}

.lighting-selection li.light-color-options {
    width: 60%;
}

.lighting-selection ul {
    list-style-type: none;
    padding: 0;
    display: flex;
    flex-flow: row wrap;
    padding: 0 0 1.5vmin 0;
}

.apply {
    border: solid 1px #000000;
    clear: both;
    width: 50%;
    margin: 1vmin auto 0;
    text-align: center;
    font-size: 1.2vmin;
    cursor: pointer;
}

.wall-selection select {
    width: 100%;
    font-family: 'ProximaNova-Regular', Arial, serif;
    font-size: 1.7vmin;
    padding: 0.7vmin 0.5vmin;
}

.wall-selection p, .trim-options p {
    font-size: 1.5vmin;
}

ul.wall-options, .trim-options {
    width: 100%;
    margin-top: 3vmin;
    list-style-type: none;
    padding: 0;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
}

.trim-options {
    margin-top: 5vmin;
}

.wall-options li, .trim-options li {
    width: 47%;
    margin-bottom: 3vmin;
}

.wall-options li p, .trim-options li p {
    text-align: center;
    margin: .5vmin 0 0;
}

.wall-picture-colorf div.zoomin, .wall-picture div.zoomin {
    float: right;
    padding: 0.5vmin 0.8vmin 0 0;
    color: #ffffff;
}

div#wall-color-family {
    clear: both;
}

#wall-color-family ul {
    list-style-type: none;
    padding: 0;
}

#wall-color-family ul {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    margin: 0;
}

#wall-color-family li {
    margin: 0 1vmin 1vmin 0;
}

#wall-color-family li.active {
    border-bottom: solid 1px #ffcc00;
    padding-bottom: 1vmin;
}

#wall-colors h5 {
    color: #858789;
    margin: .5vmin 0;
}

.wall-options-colorf {
    display: flex;
    flex-flow: row wrap;
    list-style-type: none;
    padding: 0;
    justify-content: space-between;
    margin: 0;
}

.wall-options-colorf li {
    width: 27%;
    text-align: center;
}

#siding-brick h5 {
    margin: 1vmin 0;
}

.wall-picture-colorf {
    background-color: #999999;
    width: 100%;
    height: 4.4vmin;
}

.wall-options-colorf p {
    margin: 0;
}

#wall-fiberon-cladding {
}

div.wall-filter {
    width: 100%;
    margin: 1.6vmin 0 1.2vmin;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: center;
    color: #161616;

    p {
        font-size: 1.6vmin !important;
    }
}

.wall-filter a {
    margin-bottom: 5px;
    color: #ffffff;
    cursor: pointer;
    background-color: #948e90;
    padding: 1vmin;
    line-height: 1;
    display: inline-block;
}

.wall-filter p {
    margin: 0 0 0.4vmin;
    font-size: 1.5vmin;
    margin: 0 0 0 .6vmin;

    &:last-child {
        padding-right: 0;
    }
}

.wall-filter p.active a {
    cursor: default;
    background-color: #ee2e24;
    color: #ffffff;
    padding: 1vmin;
    font-size:.97em;
}

#walls-selection p {
    font-size: 1.4vmin;
}

#wall-fiberon-cladding p {
    clear: both;
}

.zoom-dialog {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    position: fixed;
    top: 0;
    left: 0;
    display: none;
    z-index: 2000;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;

    > div {
        width: 100%;
        max-width: 750px;
        background-color: #ffffff;
    }

    .close {
        margin: 1vmin;
    }

    .content {
        clear: both;
    }

    .product-photo {
        width: 100%;
        padding-top: 60%; /* Percentage padding based on width, so this makes height a proportion of the width. */
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }
}

#deck-zoom-dialog .product-details {
    padding: 0 3vmin;

    p {
        margin: 0 0 3vmin;
        font-size: 1.0em;
        color: #858789;
    }

    h3 {
        margin: 1.6vmin 0 1.0vmin;
    }
}

#railing-zoom-dialog {
    > div {
        max-width: 1050px;
    }

    .content {
        padding: 1vmin 3vmin 3vmin;
        display: flex;
    }

    .railing-photo {
        min-width: 50%;

        img {
            width: 100%;
            max-width: 500px;
        }
    }

    .product-details {
        padding: 0 3vmin;
    }

    .product-details h3 {
        margin: 0 0 1vmin;
    }

    .product-details ul {
        margin: 0;
        padding: 0 0 0 30px;
        color: #858789;
        font-size: 1.0em;
    }

    p {
        margin: 0 0 3vmin;
        font-size: 1.0em;
        color: #858789;
    }
}

i.fa.fa-search-plus {
    cursor: pointer;
}

#wall-btns {
    display: block;

    &.hide {
        display: none;
    }
}

#blend {
    width: 100%;
    background-color: #ee2e24;
    /*background-color: #000000;*/
    color: #ffffff;
    text-align: center;
    margin-top: 1.5vmin;
    padding: 1.3vmin 3vmin;
    font-family: 'FuturaT', Arial, serif;
    font-weight: bold;
    letter-spacing: 1px;
    font-size: 2vmin;
    /*border-bottom: 1px solid #F2625B;*/
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    align-content: center;
    cursor: pointer;
    position: relative;
    text-transform: uppercase;
    justify-content: center;

    &:hover, &:focus {
        background-color: #FF3832;
    }

    &.hide {
        display: none;
    }
}


#blend:after {
    max-width: 100%;
    content: url(/img/btn-shadow.png);
    position: absolute;
    left: 0;
    top: 100%;
    overflow: hidden;
}

.blend-clad {
    margin-top: 2vmin;
    display: none;
    flex-flow: column nowrap;
    align-items: center;
    width: 100%;

    &.active {
        display: flex;
    }

    h4 {
        color: #858789;
        font-size: 16px;
        text-transform: uppercase;
        font-weight: 400;
        flex: 0 0 auto;
    }

    .remove {
        margin-top: 1.4vmin;
        flex: 0 0 auto;

        p {
            font-size: 13px;
            text-decoration: underline;
            text-transform: uppercase;
            cursor: pointer;
            margin: 0;
        }
    }
}

.blend-selected {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    width: 100%;
    flex: 0 0 auto;
    justify-content: center;

    .sixty, .forty {
        flex: 0 0 auto;
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        width: 41.8%;

        p {
            font-size: 1.5vmin;
            margin: 0;
            padding: 0;
            line-height: 1;
            flex: 0 0 auto;

            &:first-child {
                margin-bottom: 5px;
            }

            &:last-child {
                margin-top: 5px;
            }
        }

        .thumb-area {
            cursor: pointer;
            width: 100%;
            flex: 1 1 auto;
            position: relative;
            border: solid 2px #858789;
            max-height: 100px;
            overflow: hidden;

            &.active {
                border: solid 2px #ee2e24;
            }

            .selected {
                display: block;
                /*display: none;*/
                img {
                    width: 100%;
                }

                &.active {
                }
            }

            .select-color {
                display: none;
                flex-flow: column nowrap;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 999;
                background-color: #ffffff;

                &.active {
                    display: flex;
                }

                img {
                    width: 4.2vmin;
                    margin-bottom: 5px;
                }

                p {
                    font-size: 1.5vmin;
                    text-transform: uppercase;
                }
            }
        }
    }

    .switch {
        flex: 0 0 auto;
        margin: 0 10px;
        padding: 0.4vmin 1.1vmin;
        border: solid 1px #EBEBEB;
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        align-self: center;

        i {
            color: #ee2e24;
            font-size: 3vmin;
        }
    }
}






@include mobile-breakpoint() {
    .visualizer-pane {
        width: 100%;
        height: auto;
        position: static;
        flex: 0 0 auto;
    }

    main .visualizer-wrapper {
        height: auto;
        min-height: 100%;
        display: flex;
        flex-grow: 1;
    }

    section.visualizer {
        height: auto;
        min-height: 100%;
        flex-direction: column;
        flex-grow: 1;
    }

    #side-menu {
        width: 100%;
        max-width: none;
        height: auto;
        flex: 1 0 auto;
        flex-wrap: nowrap;
    }

    header .visualizer-wrapper {
        height: auto;
        padding: 0;
        position: static;
    }

    #header-bar {
        border-bottom: none;
        position: relative;

        &.active #header-stack i {
            background-color: #FF3832;
        }

        &.active #header-nav {
            display: block;
        }
    }

    #header-stack {
        text-align: right;
        display: block;

        i {
            width: 54px;
            padding: 3vmin 1vmin;
            font-size: 1.7em;
            color: white;
            background-color: #EE2E24;
            border-top: 1px solid #F2625B;
            text-align: center;
            cursor: pointer;

            &:hover {
                background-color: #FF3832;
            }
        }
    }

    #header-nav {
        width: 100%;
        height: auto;
        padding: 0;
        background-color: white;
        border-top: 1px solid #ebebeb;
        display: block;
        position: absolute;
        top: 100%;
        left: 0;
        z-index: 1500;
        display: none;

        > div, > a {
            width: 100%;
            padding: 3vmin 0;
            margin: 0;
            background-color: white;
            font-size: 1.6em;
            text-transform: none;
            display: block;

            &:hover, &:focus {
                background-color: #ebebeb;
                border-top: none;
            }
        }
    }

    #surface-menu ul {
        margin: 5vmin 0;
        font-size: 1.7em;
        text-align: center;

        li {
            margin-bottom: 3vmin;
            padding: 1vmin 4vmin;
            display: inline-flex;
        }

        li.active i {
            display: none;
        }
    }

    .product-menu {
        width: 100%;
        max-width: none;
        position: fixed;
        top: 0;
        transform: translateY(100%);

        .menu-header {
            font-size: 2.4em;
            display: block;
        }
    }

    #visualizer-controls {
        width: 100%;

        > div {
            padding-left: 5vmin;
            font-size: 1.3em;
        }
    }

    #home {
        padding: 4vmin !important;
        font-size: 1.4em;
        width: 100%;
        border-bottom: 1px solid #F2625B;

        img {
            width: 7vmin;
        }
    }

    #home:after {
        display: none;
    }

    .close img {
        width: 6.5vmin;
    }

    .product-menu {
        padding: 3vmin;

        .menu-content {
            height: auto;

            p, select {
                font-size: 1em !important;
            }
        }
    }

    .deck-filter {
        width: 100%;
        float: none;
    }

    #deck-color-family {
        margin: 1vmin 0 3vmin;
    }

    .color-option > div, .railing-color li > div, .light-color li > div {
        width: 10vmin;
        height: 10vmin;
    }

    .railing-selection, .lighting-selection {
        h3, h5 {
            font-size: 1em;
        }
    }

    .rect-thumb div.zoomin,
    .rect-thumb-small div.zoomin,
    .railing-selection .zoomin,
    .light-type-holder div.zoomin {
        font-size: 1.4em;
    }

    #railing-next {
        padding: 1.5vmin 10vmin;
    }

    #railing-zoom-dialog {
        .content {
            flex-direction: column;
        }

        .product-details {
            margin: 6vmin 0 2vmin;
        }
    }

    .decking-type-info h4, .rail-info h4, .wall-type-info h4 {
        font-size: 1em;
    }

    .lighting-selection p {
        padding: 3vmin 0;
    }

    .lighting-selection ul {
        padding-bottom: 3vmin;
    }

    .railing-steps {
        font-size: 1em;
    }

    #step-one, #step-two {
        padding-top: 0.5vmin;
    }
}