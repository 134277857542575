/*----------------------------------------------------------------
Modals
-----------------------------------------------------------------*/

.modal-show {
  visibility: visible !important;
  opacity: 1 !important;
}

#MODALS {
  visibility: hidden;
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .9);
  opacity: 0;
  z-index: 998;
  transition: all .5s;
}

#MODALS div.top-bar {
  width: 100%;
  height: 6%;
  background-color: rgb(35, 93, 123);
}

#MODALS div.close {
  position: absolute;
  right: 1%;
  top: 1.4%;
  width: 1.5vh;
  height: 1.5vh;
  background: url(/img/close.svg);
  cursor: pointer;
}
