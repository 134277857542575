/* ==========================================================================
   TOUR
   ========================================================================== */

#tour {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: none;
    z-index: 5000;
}

.tour-step {
    width: 100%;
    max-width: 340px;
    padding: 10px;
    display: none;
    z-index: 5001;

    .main {
        background-color: white;
        border: 1px solid #E0E0E0;
        border-bottom: none;
        position: relative;
    }

    p {
        margin: 0;
        padding: 3.5vmin 2vmin 4.5vmin;
        font-size: 1.2em;
        font-family: 'ProximaNova-Regular';
    }
}

.tour-step .header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    h3 {
        margin: 0;
        padding: 2vmin 0 0 2vmin;
        font-size: 3vmin;
        font-family: 'ProximaNova-Semibold';
        font-weight: normal;
    }

    .close {
        margin: 4px 4px 0 0;
        padding: 0.5vmin 1vmin;
        color: white;
        background-color: #2A4560;
        font-size: 1.8vmin;
        text-align: center;
        cursor: pointer;
    }
}

.tour-step .footer {
    width: 100%;
    color: white;
    font-size: 1.2em;
    display: flex;
    position: relative;

    div {
        padding: 1.4vmin;
        background-color: #3D3E39;
    }

    button {
        padding: 1.4vmin;
        background-color: #2a4560;
        border: 0;
        flex-grow: 1;
        border-radius: 0;
        color: #fff;
        font-family: 'ProximaNova-Regular';
        cursor: pointer;

        &:focus {
            outline: none;
        }
    }
}

.tour-step.arrow-right .main:before {
    content: '';
    width: 0;
    height: 0;
    border-top: 26px solid transparent;
    border-bottom: 26px solid transparent;
    border-left: 21px solid #E0E0E0;
    position: absolute;
    top: 50%;
    left: 100%;
    transform: translateY(-50%);
}

.tour-step.arrow-right .main:after {
    content: '';
    width: 0;
    height: 0;
    border-top: 25px solid transparent;
    border-bottom: 25px solid transparent;
    border-left: 20px solid white;
    position: absolute;
    top: 50%;
    left: 100%;
    transform: translateY(-50%);
}

.tour-step.arrow-up .main:before {
    content: '';
    width: 0;
    height: 0;
    border-left: 26px solid transparent;
    border-right: 26px solid transparent;
    border-bottom: 21px solid #E0E0E0;
    position: absolute;
    left: 50%;
    bottom: 100%;
    transform: translateX(-50%);
}

.tour-step.arrow-up .main:after {
    content: '';
    width: 0;
    height: 0;
    border-left: 25px solid transparent;
    border-right: 25px solid transparent;
    border-bottom: 20px solid white;
    position: absolute;
    left: 50%;
    bottom: 100%;
    transform: translateX(-50%);
}

.tour-step.arrow-down .footer:after {
    content: '';
    width: 0;
    height: 0;
    border-left: 25px solid transparent;
    border-right: 25px solid transparent;
    border-top: 20px solid #2a4560;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
}

.tour-step.arrow-left .main:before {
    content: '';
    width: 0;
    height: 0;
    border-top: 26px solid transparent;
    border-bottom: 26px solid transparent;
    border-right: 21px solid #E0E0E0;
    position: absolute;
    top: 50%;
    right: 100%;
    transform: translateY(-50%);
}

.tour-step.arrow-left .main:after {
    content: '';
    width: 0;
    height: 0;
    border-top: 25px solid transparent;
    border-bottom: 25px solid transparent;
    border-right: 20px solid white;
    position: absolute;
    top: 50%;
    right: 100%;
    transform: translateY(-50%);
}