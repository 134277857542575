@charset "UTF-8";
@-ms-viewport {
  width: device-width;
}
@font-face {
  font-family: "FuturaBT";
  src: url("/css/webfonts/FuturaBT-Medium.eot?#iefix") format("embedded-opentype"), url("/css/webfonts/FuturaBT-Medium.woff") format("woff"), url("/css/webfonts/FuturaBT-Medium.ttf") format("truetype"), url("/css/webfonts/FuturaBT-Medium.svg#FuturaBT-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "FuturaT";
  src: url("/css/webfonts/FuturaTBold.eot?#iefix") format("embedded-opentype"), url("/css/webfonts/FuturaTBold.woff") format("woff"), url("/css/webfonts/FuturaTBold.ttf") format("truetype"), url("/css/webfonts/FuturaTBold.svg#FuturaTBold") format("svg");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "ProximaNova-Regular";
  src: url("/css/webfonts/ProximaNova-Regular.eot?#iefix") format("embedded-opentype"), url("/css/webfonts/ProximaNova-Regular.otf") format("opentype"), url("/css/webfonts/ProximaNova-Regular.woff") format("woff"), url("/css/webfonts/ProximaNova-Regular.ttf") format("truetype"), url("/css/webfonts/ProximaNova-Regular.svg#ProximaNova-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
}
*, *:before, *:after {
  box-sizing: inherit;
}

.cham {
  color: #242424;
  font-size: 1em;
  line-height: 1.4;
  font-family: "ProximaNova-Regular", Arial, serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Remove text-shadow in selection highlight */
::-moz-selection {
  background: #b3d4fc;
  text-shadow: none;
}

::selection {
  background: #b3d4fc;
  text-shadow: none;
}

/* A better looking default horizontal rule */
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

/* Remove the gap between element and the bottom of their containers */
audio, canvas, iframe, img, svg, video {
  vertical-align: middle;
}

/* Remove default fieldset styles. */
fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

/* Allow only vertical resizing of textareas. */
textarea {
  resize: vertical;
}

/* Clearfix: contain floats */
.clearfix:before, .clearfix:after {
  content: " ";
  display: table;
}

.clearfix:after {
  clear: both;
}

/* Hack to fix ie adding extra spacing around scaled svgs */
img[src$=".svg"] {
  width: 100%;
}

/* Portrait iphone 6 and above*/
@media only screen and (min-device-width: 375px) and (max-device-width: 1024px) and (orientation: portrait) {
  body.non-mobile #rotate-overlay {
    display: block;
  }
}
body.mobile #mobile-overlay {
  display: block;
}

.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Chrome/Safari/Opera */
  -khtml-user-select: none; /* Konqueror */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

/*----------------------------------------------------------------
Ghost Center
-----------------------------------------------------------------*/
.ghost-center {
  text-align: center;
  white-space: nowrap;
}

.ghost-center:before {
  content: "";
  width: 0;
  height: 100%;
  margin-left: -0.2em;
  /* Adjust for spacing between inline-block elements. */
  display: inline-block;
  vertical-align: middle;
}

.ghost-center > div {
  text-align: left;
  white-space: normal;
  display: inline-block;
  vertical-align: middle;
}

/* ==========================================================================
    GLOBAL
   ========================================================================== */
.cham {
  width: 100%;
  height: auto;
  margin: 0 auto;
  overflow: hidden;
  box-sizing: border-box;
}

#MAIN {
  width: 100%;
  height: 100%;
}

.visualizer { /* Override Fiberon css. */
  text-align: left;
  padding-bottom: 0;
}

h1, h2 {
  padding: 0 0 0 1.5%;
  margin: 0;
  font-family: "ProximaNova-Regular", Arial, serif;
  font-weight: normal;
  color: #242424;
  line-height: inherit;
}

h1 {
  padding: 5% 0 0 1.5%;
  font-size: 5.9vh;
}

h2 {
  font-size: 3vh;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

h3, h4 {
  font-weight: bold;
  line-height: inherit;
}

p {
  font-family: "ProximaNova-Regular", Arial, serif;
  line-height: inherit;
}

form {
  /* Overwrite Fiberon css */
  margin-bottom: 0;
}

header {
  height: 13%;
  width: 100%;
  padding-top: 0;
  background-color: white;
}

#title-center {
  margin: 0 auto;
  padding: 1.5vmin 0 1.3vmin;
  width: 100%;
  max-width: 1024px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#title-center h1 {
  color: #ffffff;
  font-family: "FuturaT", Arial, serif;
  font-weight: bold;
  padding: 2% 0 0 0;
  text-align: center;
}

main {
  height: 87%;
  position: relative;
  margin: 0 auto;
  width: 100%;
}

main section {
  width: 100%;
  height: 100%;
}

.hidden {
  display: none;
}

.invisible {
  visibility: hidden;
}

.red-button, .mktoForm .mktoButtonWrap {
  margin-bottom: 2vmin;
  position: relative;
  display: inline-block;
}
.red-button button, .mktoForm .mktoButtonWrap button {
  max-width: 100%;
  padding: 2.6vmin 3vmin;
  background-color: #ee2e24;
  color: #ffffff;
  font-size: 1.2em;
  font-family: "FuturaT", Arial, serif;
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 1;
  text-transform: uppercase;
  border: none;
  cursor: pointer;
  /* Firefox - flexbox bugs when using directly on button elements. */
}
.red-button button > span, .mktoForm .mktoButtonWrap button > span {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.red-button button:hover, .red-button button:focus, .mktoForm .mktoButtonWrap button:hover, .mktoForm .mktoButtonWrap button:focus {
  background-color: #FF3832;
  outline: none;
}
.red-button button img, .mktoForm .mktoButtonWrap button img {
  margin-left: 10vmin;
}
.red-button:after, .mktoForm .mktoButtonWrap:after {
  max-width: 100%;
  content: url("/img/btn-shadow.png");
  position: absolute;
  left: 0;
  top: 100%;
  overflow: hidden;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait), all and (max-width: 768px) {
  .cham {
    height: auto;
  }
  #MAIN {
    display: flex;
    flex-direction: column;
  }
  header {
    flex-shrink: 0;
  }
  main {
    height: auto;
    position: static;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }
  main section {
    position: static;
  }
  .red-button button, .mktoForm .mktoButtonWrap button {
    padding: 4vmin 8vmin;
  }
  header {
    height: auto;
  }
  h1 {
    font-size: 4.9vh;
  }
}
/*CLADDING ADJUSTMENTS*/
#roomscene-data li[data-index="10"] {
  display: none;
}

#roomscene-data li[data-index="11"] {
  display: none;
}

p.tab-link[data-tab="1"] {
  display: none;
}

a#get-samples-cladding {
  display: none;
}

div#estimate-cladding {
  display: none;
}

.alertify-logs > * {
  padding: 12px 24px;
  color: #fff;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
  border-radius: 1px;
  transition: 0.2s all;
  display: block !important;
}
.alertify-logs > *, .alertify-logs > *.default {
  background: rgba(0, 0, 0, 0.8);
}
.alertify-logs > *.error {
  background: rgba(244, 67, 54, 0.8);
}
.alertify-logs > *.success {
  background: rgba(76, 175, 80, 0.9);
}

.alertify {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.3);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
}
.alertify.hide {
  opacity: 0;
  pointer-events: none;
}
.alertify, .alertify.show {
  box-sizing: border-box;
  transition: all 0.33s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.alertify, .alertify * {
  box-sizing: border-box;
}
.alertify .dialog {
  padding: 12px;
}
.alertify .dialog, .alertify .alert {
  width: 100%;
  margin: 0 auto;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.alertify .dialog > *, .alertify .alert > * {
  width: 400px;
  max-width: 95%;
  margin: 0 auto;
  text-align: center;
  padding: 12px;
  background: #fff;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.14), 0 4px 5px 0 rgba(0, 0, 0, 0.098), 0 1px 10px 0 rgba(0, 0, 0, 0.084);
}
.alertify .dialog .msg, .alertify .alert .msg {
  padding: 12px;
  margin-bottom: 12px;
  margin: 0;
  text-align: left;
}
.alertify .dialog input:not(.form-control), .alertify .alert input:not(.form-control) {
  margin-bottom: 15px;
  width: 100%;
  font-size: 100%;
  padding: 12px;
}
.alertify .dialog input:not(.form-control):focus, .alertify .alert input:not(.form-control):focus {
  outline-offset: -2px;
}
.alertify .dialog nav, .alertify .alert nav {
  text-align: right;
}
.alertify .dialog nav button:not(.btn):not(.pure-button):not(.md-button):not(.mdl-button), .alertify .alert nav button:not(.btn):not(.pure-button):not(.md-button):not(.mdl-button) {
  background: transparent;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.87);
  position: relative;
  outline: 0;
  border: 0;
  display: inline-block;
  align-items: center;
  padding: 0 6px;
  margin: 6px 8px;
  line-height: 36px;
  min-height: 36px;
  white-space: nowrap;
  min-width: 88px;
  text-align: center;
  text-transform: uppercase;
  font-size: 14px;
  text-decoration: none;
  cursor: pointer;
  border: 1px solid transparent;
  border-radius: 2px;
}
.alertify .dialog nav button:not(.btn):not(.pure-button):not(.md-button):not(.mdl-button):hover, .alertify .dialog nav button:not(.btn):not(.pure-button):not(.md-button):not(.mdl-button):active, .alertify .alert nav button:not(.btn):not(.pure-button):not(.md-button):not(.mdl-button):hover, .alertify .alert nav button:not(.btn):not(.pure-button):not(.md-button):not(.mdl-button):active {
  background-color: rgba(0, 0, 0, 0.05);
}
.alertify .dialog nav button:not(.btn):not(.pure-button):not(.md-button):not(.mdl-button):focus, .alertify .alert nav button:not(.btn):not(.pure-button):not(.md-button):not(.mdl-button):focus {
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.alertify .dialog nav button.btn, .alertify .alert nav button.btn {
  margin: 6px 4px;
}

.alertify-logs {
  position: fixed;
  z-index: 99999;
}
.alertify-logs.bottom, .alertify-logs:not(.top) {
  bottom: 16px;
}
.alertify-logs.left, .alertify-logs:not(.right) {
  left: 16px;
}
.alertify-logs.left > *, .alertify-logs:not(.right) > * {
  float: left;
  transform: translate3d(0, 0, 0);
  height: auto;
}
.alertify-logs.left > *.show, .alertify-logs:not(.right) > *.show {
  left: 0;
}
.alertify-logs.left > *, .alertify-logs.left > *.hide, .alertify-logs:not(.right) > *, .alertify-logs:not(.right) > *.hide {
  left: -110%;
}
.alertify-logs.right {
  right: 16px;
}
.alertify-logs.right > * {
  float: right;
  transform: translate3d(0, 0, 0);
}
.alertify-logs.right > *.show {
  right: 0;
  opacity: 1;
}
.alertify-logs.right > *, .alertify-logs.right > *.hide {
  right: -110%;
  opacity: 0;
}
.alertify-logs.top {
  top: 0;
}
.alertify-logs > * {
  box-sizing: border-box;
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  position: relative;
  clear: both;
  backface-visibility: hidden;
  perspective: 1000;
}
.alertify-logs > * {
  max-height: 0;
  margin: 0;
  padding: 0;
  overflow: hidden;
  opacity: 0;
  pointer-events: none;
}
.alertify-logs > *.show {
  margin-top: 12px;
  opacity: 1;
  max-height: 1000px;
  padding: 12px;
  pointer-events: auto;
}

/* ----------------------------------------------------------------
Landing
-----------------------------------------------------------------*/
.landing {
  display: none;
}

header nav.landing {
  font-size: 2.5vh;
  width: 100%;
  position: relative;
  /*padding: 0 2.9% 0 0;*/
  padding: 0;
}

#landing-projects {
  cursor: pointer;
}

section.landing {
  width: 100%;
  height: 100%;
}

section.landing div.upload {
  width: 97%;
  margin: 2% auto 0 auto;
  position: relative;
  cursor: pointer;
}

section.landing div.callout {
  cursor: pointer;
}

#roomscene-data {
  padding: 0;
  width: 100%;
  margin: 0 auto;
  list-style-type: none;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  overflow: hidden;
}

#roomscene-data li {
  width: 25%;
  margin: 0;
  padding: 0;
}

#roomscene-data img[src] {
  width: 100%;
  height: auto;
  background-image: none;
}

/* ----------------------------------------------------------------
Scenes
-----------------------------------------------------------------*/
section.scenes {
  display: none;
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
}
section.scenes > div {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
section.scenes h2 {
  text-align: center;
  font-weight: 500;
  font-size: 3.5vmin;
  padding: 2vmin 0 1.5vmin;
  font-family: "FuturaBT", Arial, serif;
}

.scene-wrapper {
  width: 27%;
  padding: 0;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}

#roomscene-data {
  padding: 0;
  margin: 0;
  list-style-type: none;
  display: flex;
  flex-flow: row wrap;
}

#roomscene-data li {
  flex: 0 1 43%;
  /*margin: 0 0 20px 0;*/
  margin: 5px;
  padding: 0;
  font-size: 2vmin;
  border: 2px solid transparent;
  cursor: pointer;
}
#roomscene-data li:hover, #roomscene-data li.active {
  border-color: #EE2E24;
}
#roomscene-data li.active {
  cursor: default;
}

#roomscene-data img {
  width: 100%;
  height: 15vw;
}

#roomscene-data img[src] {
  width: 100%;
  height: auto;
  background-image: none;
}

#select-scene-section {
  min-height: 200px;
  display: flex;
  flex-flow: row wrap;
  flex-grow: 1;
  justify-content: space-around;
}

#big-scene-wrapper {
  width: 55%;
  position: relative;
}
#big-scene-wrapper .loading-msg {
  top: 0 !important;
  transform: translateX(-50%);
}

#bb-box {
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
}

#big-scene {
  width: 100%;
  height: 80%;
  text-align: center;
  margin: 0 auto;
  position: relative;
  flex-grow: 2;
}
#big-scene img {
  width: 100%;
  object-fit: contain;
}

/*#scenes-buttons {
	padding: 30px 0;
    display:flex;
    flex-flow:row wrap;
    justify-content:center;
	align-items: flex-start;
}*/
#scenes-buttons {
  padding: 30px 0;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  width: 100%;
  align-items: flex-start;
}

#landing-projects {
  border: solid 1px #ee2e24;
  color: #ee2e24;
  padding: 2.6vmin 9vmin;
  font-size: 1.2em;
  font-family: "FuturaT", Arial, serif;
  font-weight: bold;
  margin-right: 3vmin;
  letter-spacing: 1px;
  line-height: 1;
  text-transform: uppercase;
}

#get-started img {
  margin-left: 6vmin;
}

.margin-bottom-30 {
  margin-bottom: 30px;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait), all and (max-width: 768px) {
  section.scenes > div {
    display: block;
  }
  section.scenes h2 {
    font-size: 2em;
  }
  #select-scene-section {
    padding: 0px 9vmin;
    display: block;
  }
  #roomscene-data li {
    flex: 0 0 29%;
  }
  .scene-wrapper {
    width: 100%;
    margin: 4vmin 0 5vmin;
  }
  #scenes-buttons {
    padding-bottom: 0;
  }
  #big-scene-wrapper {
    width: 100%;
    min-height: 200px;
  }
  #big-scene {
    height: auto;
    position: relative;
  }
  #landing-projects {
    margin-bottom: 8vmin;
    padding-top: 4vmin;
    padding-bottom: 4vmin;
  }
  #roomscene-data li {
    margin: 1%;
  }
}
/* ----------------------------------------------------------------
Visualizer
-----------------------------------------------------------------*/
.visualizer-wrapper {
  display: none;
  width: 100%;
  height: 100%;
}

main .visualizer-wrapper {
  max-width: 1280px;
  margin: 0 auto;
}

header {
  position: relative;
}

header .landing {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

header .visualizer-wrapper {
  width: 100%;
  padding: 6.5vmin 0 2.5vmin;
}

header .landing {
  height: 100%;
  background: url(/img/header.jpg) repeat-x;
}

header .visualizer {
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

header nav.visualizer div {
  margin-top: 0.8vmin;
  display: inline-block;
  font-size: 1.5vmin;
  line-height: 3vmin;
  padding: 0 1% 0% 1%;
  text-align: center;
  cursor: pointer;
  box-sizing: border-box;
}

header nav.visualizer div:last-child {
  padding: 0 3% 0 1%;
}

header nav.visualizer i.fa {
  display: block;
  font-size: 2.5vh;
  cursor: pointer;
}

section.visualizer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row-reverse;
}

.visualizer-pane {
  height: 100%;
  position: relative;
  flex-grow: 1;
  overflow: hidden;
  background-color: #ebebeb;
}

#visualizer {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

#side-menu {
  width: 25%;
  max-width: 300px;
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-start;
  -webkit-tap-highlight-color: transparent;
}

#estimate, #sign-in, #share, #get-samples, #get-samples-cladding, #estimate-cladding {
  width: 159px;
  background-color: #ebebeb;
  text-align: center;
  margin: 0 1vmin;
  padding: 1vmin 0;
  font-family: Arial, serif;
  font-weight: bold;
  color: #858789;
  font-size: 1.3vmin;
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
  border-top: 2px solid transparent;
}

#header-bar {
  width: 100%;
  height: 100%;
  border-bottom: 1px solid #ebebeb;
}

#header-stack {
  display: none;
}

#header-nav {
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: flex-end;
}

div#share.active {
  border-top: 2px solid #ee2e24;
  background-color: #ffffff;
  border-right: 1px solid #ebebeb;
  border-left: 1px solid #ebebeb;
}

#surface-menu {
  width: 100%;
  background-color: white;
  position: relative;
  overflow: hidden;
  z-index: 10;
}

#surface-menu ul {
  list-style-type: none;
  padding: 0;
  color: #948e90;
  font-family: "FuturaBT", Arial, serif;
  font-weight: 500;
  font-size: 2.5vmin;
  margin-bottom: 4vmin;
}

#surface-menu li {
  margin-bottom: 1vmin;
  padding: 0 0 0 3vmin;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
#surface-menu li:hover, #surface-menu li.active {
  color: #EE2E24;
}
#surface-menu li i {
  padding-right: 4vmin;
  font-size: 0.6em;
  display: none;
}
#surface-menu li.active i {
  display: block;
}

#home {
  width: 92%;
  background-color: #ee2e24;
  /*background-color: #000000;*/
  color: #ffffff;
  text-align: center;
  margin-top: 1.2vmin;
  padding: 2vmin 3vmin;
  font-family: "FuturaT", Arial, serif;
  font-weight: bold;
  letter-spacing: 1px;
  font-size: 2vmin;
  /*border-bottom: 1px solid #F2625B;*/
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  align-content: center;
  cursor: pointer;
  position: relative;
}
#home:hover, #home:focus {
  background-color: #FF3832;
}

#home:after {
  max-width: 100%;
  content: url(/img/btn-shadow.png);
  position: absolute;
  left: 0;
  top: 100%;
  overflow: hidden;
}

#home img {
  width: 3.2vmin;
  margin-right: 4vmin;
}

div#save, div#print, div#undo-redo, div#zoom {
  color: #ee2e24;
  font-family: "FuturaT", Arial, serif;
  font-weight: bold;
  font-size: 1.6vmin;
  padding: 0.8vmin 0 0.8vmin 3vmin;
}
div#save img, div#save span, div#print img, div#print span, div#undo-redo img, div#undo-redo span, div#zoom img, div#zoom span {
  display: inline-block;
  vertical-align: middle;
}
div#save > img:first-child, div#print > img:first-child, div#undo-redo > img:first-child, div#zoom > img:first-child {
  margin-right: 6px;
}
div#save > img:last-child, div#print > img:last-child, div#undo-redo > img:last-child, div#zoom > img:last-child {
  margin-left: 6px;
}

div#save img, div#save span, div#print img, div#print span, div#undo-redo, div#zoom {
  cursor: pointer;
}

#undo-redo {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

#undo, #redo {
  padding: 0.8vmin 0;
}

#undo img {
  margin-right: 6px;
}

#redo img {
  margin-left: 6px;
}

#product-menus {
  margin: 0;
  padding: 0;
  list-style: none;
}

.product-menu {
  height: 100%;
  width: 50%;
  max-width: 560px;
  padding: 1vmin 2vmin;
  background-color: #ffffff;
  position: absolute;
  left: 0;
  z-index: 101;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  transform: translateX(-100%);
  transition: 0.4s transform ease-out;
  will-change: transform;
  z-index: 1500;
  -webkit-tap-highlight-color: transparent;
}
.product-menu.active {
  transform: none;
}
.product-menu .menu-header {
  padding: 0;
  clear: both;
  display: none;
}
.product-menu .menu-content {
  width: 100%;
}

.no-products {
  clear: both;
}

.deck-filter {
  width: 74%;
  margin: 1.6vmin 0 1.2vmin;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  float: left;
  color: #161616;
}
.deck-filter p {
  font-size: 1.6vmin !important;
}

.deck-filter a {
  color: #948e90;
  cursor: pointer;
}

.deck-filter p {
  margin: 0 0 0.4vmin;
  font-size: 1.5vmin;
  padding-right: 2vmin;
}
.deck-filter p:last-child {
  padding-right: 0;
}

.deck-filter p.active a {
  color: #ee2e24;
  cursor: default;
}

.decking-options li.active, .decking-options-colorf li.active {
  cursor: default;
}

.product.active .rect-thumb:before,
.product.active .rect-thumb-small:before {
  content: "";
  width: 100%;
  height: 100%;
  border: 2px solid #ee2e24;
  position: absolute;
  top: 0;
  left: 0;
}

.close {
  font-size: 1em;
  float: right;
  cursor: pointer;
  padding-right: 25px;
}
.close img {
  width: 2.8vmin;
  height: 100%;
  margin-left: 8px;
}
.close span, .close img {
  display: inline-block;
  vertical-align: middle;
}

.tab {
  display: none;
}
.tab.active {
  display: block;
}

.product {
  cursor: pointer;
}

.deck-selection select {
  width: 100%;
  margin-bottom: 2vmin;
  font-family: "ProximaNova-Regular", Arial, serif;
  font-size: 1.7vmin;
  padding: 0.7vmin 0.5vmin;
  cursor: pointer;
}

.deck-selection p {
  font-size: 1.5vmin;
}

ul.decking-options {
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.decking-options li {
  width: 47%;
  margin-bottom: 3vmin;
}

.rect-thumb {
  width: 100%;
  padding-bottom: 55%; /* Percentage padding based on width, so this makes height a proportion of the width. */
  position: relative;
}

.rect-thumb-small {
  width: 100%;
  padding-bottom: 50%; /* Percentage padding based on width, so this makes height a proportion of the width. */
  position: relative;
}

.rect-thumb, .rect-thumb-small {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.decking-options li p {
  text-align: center;
  margin: 0.5vmin 0 0;
}

.rect-thumb div.zoomin, .rect-thumb-small div.zoomin {
  float: right;
  padding: 0.5vmin 0.8vmin;
  color: #ffffff;
  position: relative;
  cursor: pointer;
  z-index: 2;
}

.color-option {
  padding-bottom: 1vmin;
  border-bottom: 1px solid transparent;
}
.color-option.active {
  border-color: #ee2e24;
}
.color-option.active > div {
  cursor: default;
}
.color-option > div {
  width: 4vmin;
  height: 4vmin;
  cursor: pointer;
  border-radius: 50%;
}
.color-option.all > div {
  border: 1px solid #AAAAAA;
  line-height: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: content-box;
}

.deck-colors {
  margin-bottom: 1vmin;
}

div#deck-color-family {
  clear: both;
}

#deck-color-family ul {
  list-style-type: none;
  padding: 0;
}

#deck-color-family ul {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin: 0;
}

#deck-color-family li {
  margin: 0 1.5vmin 1vmin 0;
}

.deck-selection h5 {
  color: #858789;
  margin: 0.5vmin 0;
  font-size: 1em;
  font-weight: 400;
  text-transform: uppercase;
  /* Override Fiberon css */
  border-bottom: none;
  padding: 0;
  text-align: left;
}

.deck-styles .product-group.single-shown h5 {
  display: none;
}

.deck-colors .product-group {
  margin-left: -3%;
}
.deck-colors h5 {
  margin-left: 3%;
}

.decking-options-colorf {
  display: flex;
  flex-flow: row wrap;
  list-style-type: none;
  padding: 0;
  justify-content: flex-start;
  margin: 0;
}

.decking-options-colorf li {
  width: 27%;
  margin: 0 3%;
  padding-bottom: 0.5vmin;
  text-align: center;
}

.decking-options-colorf p {
  margin: 0;
}

.decking-type-info h4 {
  margin: 0 0 0.3vmin;
  font-size: 1.7vmin;
}
.decking-type-info p {
  margin: 0 0 3vmin;
}

.wall-type-info h4 {
  margin: 2vmin 0 0.3vmin;
  font-size: 1.7vmin;
}
.wall-type-info p {
  margin: 0;
}
.wall-type-info.hide {
  display: none;
}

.railing-options {
  padding: 0 0 2vmin;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  list-style-type: none;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin: 0;
}

.railing-selection .zoomin {
  padding: 0.8vmin;
  color: black;
  font-size: 2.5vmin;
  line-height: 1;
  position: absolute;
  bottom: 0;
  right: 0;
}

.railing-options li {
  width: 48%;
}

.railing-options li p {
  text-align: left;
  padding-top: 7px;
}

.railing-thumb, .lighting-thumb {
  width: 100%;
  padding-bottom: 60%;
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.railing-color, .light-color {
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
}

.railing-color li, .light-color li {
  width: auto;
  margin: 0 2vmin 1vmin;
  padding-bottom: 1vmin;
  border-bottom: 1px solid transparent;
}
.railing-color li.active, .light-color li.active {
  border-color: #ee2e24;
}
.railing-color li.active > div, .light-color li.active > div {
  cursor: default;
}
.railing-color li > div, .light-color li > div {
  height: 5vmin;
  width: 5vmin;
  border-radius: 50%;
  cursor: pointer;
}

.railing-additional .railing-color, .light-color {
  justify-content: flex-start;
}
.railing-additional .railing-color li, .light-color li {
  margin: 0 1vmin 1vmin;
}

.railing-selection h3, .railing-selection h5, .lighting-selection h3, .lighting-selection h5 {
  margin: 1.3vmin 0 1vmin;
  color: #858789;
  font-size: 1em;
  font-weight: 400;
  text-transform: uppercase;
  /* Override Fiberon css */
  border-bottom: none;
  padding: 0;
  text-align: left;
}

.railing-selection p {
  font-size: 1.5vmin;
}

.rail-info h4 {
  margin: 0;
  font-size: 1.7vmin;
}
.rail-info p {
  margin: 0.3vmin 0 2vmin;
}

#railing-next {
  background-color: #ee2e24;
  color: #ffffff;
  margin-bottom: 1vmin;
  padding: 0.5vmin 3vmin;
  cursor: pointer;
  text-align: center;
  text-transform: uppercase;
  display: inline-block;
}

.railing-steps {
  margin: 1.6vmin 0 1.2vmin;
  font-size: 1.6vmin;
}

.railing-steps p {
  margin: 0 0 0.4vmin 0.2vmin;
  padding: 0.6vmin 2vmin 0.3vmin;
  font-size: 1.7vmin;
  color: #fff;
  display: inline-block;
  cursor: pointer;
  background-color: #948e90;
}
.railing-steps p.active {
  background-color: #ee2e24;
  color: #ffffff;
  cursor: default;
}

.rail-step {
  clear: both;
  display: none;
}
.rail-step.active {
  display: block;
}
.rail-step .centered {
  text-align: center;
}

.railing-type-holder {
  width: 40%;
}

.railing-additional {
  display: none;
}
.railing-additional.active {
  display: block;
}

#step-two li.rail-color-options2 {
  width: 60%;
}

#step-two ul {
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-flow: row wrap;
  margin: 0;
}
#step-two ul p {
  margin: 2.4vmin 0;
  text-align: center;
}

#step-two h5 {
  margin: 1vmin 0;
}

.railing-additional > ul > li, .lighting-selection > ul > li {
  padding: 0 1vmin;
}

.railing-color2 li {
  background-color: #000000;
  border: solid 1px #000000;
  height: 5vmin;
  width: 5vmin;
  border-radius: 30px;
  cursor: pointer;
  margin: 0 1vmin;
}

.railing-color2 {
  display: flex;
  flex-flow: row wrap;
  list-style-type: none;
  padding: 0;
  justify-content: flex-start;
}

.light-type-holder div.zoomin {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  height: 100%;
  align-content: flex-end;
  padding: 0 0.8vmin 0.8vmin 0px;
  color: #000000;
  font-size: 2.5vmin;
  position: absolute;
  bottom: 0;
  right: 0;
}

.lighting-selection p {
  margin: 0;
  padding: 0.5vmin 0 0.5vmin;
  clear: both;
  font-size: 1.5vmin;
  text-align: center;
}

.light-type-holder {
  width: 40%;
}

.lighting-selection li.light-color-options {
  width: 60%;
}

.lighting-selection ul {
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-flow: row wrap;
  padding: 0 0 1.5vmin 0;
}

.apply {
  border: solid 1px #000000;
  clear: both;
  width: 50%;
  margin: 1vmin auto 0;
  text-align: center;
  font-size: 1.2vmin;
  cursor: pointer;
}

.wall-selection select {
  width: 100%;
  font-family: "ProximaNova-Regular", Arial, serif;
  font-size: 1.7vmin;
  padding: 0.7vmin 0.5vmin;
}

.wall-selection p, .trim-options p {
  font-size: 1.5vmin;
}

ul.wall-options, .trim-options {
  width: 100%;
  margin-top: 3vmin;
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.trim-options {
  margin-top: 5vmin;
}

.wall-options li, .trim-options li {
  width: 47%;
  margin-bottom: 3vmin;
}

.wall-options li p, .trim-options li p {
  text-align: center;
  margin: 0.5vmin 0 0;
}

.wall-picture-colorf div.zoomin, .wall-picture div.zoomin {
  float: right;
  padding: 0.5vmin 0.8vmin 0 0;
  color: #ffffff;
}

div#wall-color-family {
  clear: both;
}

#wall-color-family ul {
  list-style-type: none;
  padding: 0;
}

#wall-color-family ul {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin: 0;
}

#wall-color-family li {
  margin: 0 1vmin 1vmin 0;
}

#wall-color-family li.active {
  border-bottom: solid 1px #ffcc00;
  padding-bottom: 1vmin;
}

#wall-colors h5 {
  color: #858789;
  margin: 0.5vmin 0;
}

.wall-options-colorf {
  display: flex;
  flex-flow: row wrap;
  list-style-type: none;
  padding: 0;
  justify-content: space-between;
  margin: 0;
}

.wall-options-colorf li {
  width: 27%;
  text-align: center;
}

#siding-brick h5 {
  margin: 1vmin 0;
}

.wall-picture-colorf {
  background-color: #999999;
  width: 100%;
  height: 4.4vmin;
}

.wall-options-colorf p {
  margin: 0;
}

div.wall-filter {
  width: 100%;
  margin: 1.6vmin 0 1.2vmin;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
  color: #161616;
}
div.wall-filter p {
  font-size: 1.6vmin !important;
}

.wall-filter a {
  margin-bottom: 5px;
  color: #ffffff;
  cursor: pointer;
  background-color: #948e90;
  padding: 1vmin;
  line-height: 1;
  display: inline-block;
}

.wall-filter p {
  margin: 0 0 0.4vmin;
  font-size: 1.5vmin;
  margin: 0 0 0 0.6vmin;
}
.wall-filter p:last-child {
  padding-right: 0;
}

.wall-filter p.active a {
  cursor: default;
  background-color: #ee2e24;
  color: #ffffff;
  padding: 1vmin;
  font-size: 0.97em;
}

#walls-selection p {
  font-size: 1.4vmin;
}

#wall-fiberon-cladding p {
  clear: both;
}

.zoom-dialog {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  display: none;
  z-index: 2000;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.zoom-dialog > div {
  width: 100%;
  max-width: 750px;
  background-color: #ffffff;
}
.zoom-dialog .close {
  margin: 1vmin;
}
.zoom-dialog .content {
  clear: both;
}
.zoom-dialog .product-photo {
  width: 100%;
  padding-top: 60%; /* Percentage padding based on width, so this makes height a proportion of the width. */
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

#deck-zoom-dialog .product-details {
  padding: 0 3vmin;
}
#deck-zoom-dialog .product-details p {
  margin: 0 0 3vmin;
  font-size: 1em;
  color: #858789;
}
#deck-zoom-dialog .product-details h3 {
  margin: 1.6vmin 0 1vmin;
}

#railing-zoom-dialog > div {
  max-width: 1050px;
}
#railing-zoom-dialog .content {
  padding: 1vmin 3vmin 3vmin;
  display: flex;
}
#railing-zoom-dialog .railing-photo {
  min-width: 50%;
}
#railing-zoom-dialog .railing-photo img {
  width: 100%;
  max-width: 500px;
}
#railing-zoom-dialog .product-details {
  padding: 0 3vmin;
}
#railing-zoom-dialog .product-details h3 {
  margin: 0 0 1vmin;
}
#railing-zoom-dialog .product-details ul {
  margin: 0;
  padding: 0 0 0 30px;
  color: #858789;
  font-size: 1em;
}
#railing-zoom-dialog p {
  margin: 0 0 3vmin;
  font-size: 1em;
  color: #858789;
}

i.fa.fa-search-plus {
  cursor: pointer;
}

#wall-btns {
  display: block;
}
#wall-btns.hide {
  display: none;
}

#blend {
  width: 100%;
  background-color: #ee2e24;
  /*background-color: #000000;*/
  color: #ffffff;
  text-align: center;
  margin-top: 1.5vmin;
  padding: 1.3vmin 3vmin;
  font-family: "FuturaT", Arial, serif;
  font-weight: bold;
  letter-spacing: 1px;
  font-size: 2vmin;
  /*border-bottom: 1px solid #F2625B;*/
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  align-content: center;
  cursor: pointer;
  position: relative;
  text-transform: uppercase;
  justify-content: center;
}
#blend:hover, #blend:focus {
  background-color: #FF3832;
}
#blend.hide {
  display: none;
}

#blend:after {
  max-width: 100%;
  content: url(/img/btn-shadow.png);
  position: absolute;
  left: 0;
  top: 100%;
  overflow: hidden;
}

.blend-clad {
  margin-top: 2vmin;
  display: none;
  flex-flow: column nowrap;
  align-items: center;
  width: 100%;
}
.blend-clad.active {
  display: flex;
}
.blend-clad h4 {
  color: #858789;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 400;
  flex: 0 0 auto;
}
.blend-clad .remove {
  margin-top: 1.4vmin;
  flex: 0 0 auto;
}
.blend-clad .remove p {
  font-size: 13px;
  text-decoration: underline;
  text-transform: uppercase;
  cursor: pointer;
  margin: 0;
}

.blend-selected {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  width: 100%;
  flex: 0 0 auto;
  justify-content: center;
}
.blend-selected .sixty, .blend-selected .forty {
  flex: 0 0 auto;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  width: 41.8%;
}
.blend-selected .sixty p, .blend-selected .forty p {
  font-size: 1.5vmin;
  margin: 0;
  padding: 0;
  line-height: 1;
  flex: 0 0 auto;
}
.blend-selected .sixty p:first-child, .blend-selected .forty p:first-child {
  margin-bottom: 5px;
}
.blend-selected .sixty p:last-child, .blend-selected .forty p:last-child {
  margin-top: 5px;
}
.blend-selected .sixty .thumb-area, .blend-selected .forty .thumb-area {
  cursor: pointer;
  width: 100%;
  flex: 1 1 auto;
  position: relative;
  border: solid 2px #858789;
  max-height: 100px;
  overflow: hidden;
}
.blend-selected .sixty .thumb-area.active, .blend-selected .forty .thumb-area.active {
  border: solid 2px #ee2e24;
}
.blend-selected .sixty .thumb-area .selected, .blend-selected .forty .thumb-area .selected {
  display: block;
  /*display: none;*/
}
.blend-selected .sixty .thumb-area .selected img, .blend-selected .forty .thumb-area .selected img {
  width: 100%;
}
.blend-selected .sixty .thumb-area .select-color, .blend-selected .forty .thumb-area .select-color {
  display: none;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: #ffffff;
}
.blend-selected .sixty .thumb-area .select-color.active, .blend-selected .forty .thumb-area .select-color.active {
  display: flex;
}
.blend-selected .sixty .thumb-area .select-color img, .blend-selected .forty .thumb-area .select-color img {
  width: 4.2vmin;
  margin-bottom: 5px;
}
.blend-selected .sixty .thumb-area .select-color p, .blend-selected .forty .thumb-area .select-color p {
  font-size: 1.5vmin;
  text-transform: uppercase;
}
.blend-selected .switch {
  flex: 0 0 auto;
  margin: 0 10px;
  padding: 0.4vmin 1.1vmin;
  border: solid 1px #EBEBEB;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  align-self: center;
}
.blend-selected .switch i {
  color: #ee2e24;
  font-size: 3vmin;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait), all and (max-width: 768px) {
  .visualizer-pane {
    width: 100%;
    height: auto;
    position: static;
    flex: 0 0 auto;
  }
  main .visualizer-wrapper {
    height: auto;
    min-height: 100%;
    display: flex;
    flex-grow: 1;
  }
  section.visualizer {
    height: auto;
    min-height: 100%;
    flex-direction: column;
    flex-grow: 1;
  }
  #side-menu {
    width: 100%;
    max-width: none;
    height: auto;
    flex: 1 0 auto;
    flex-wrap: nowrap;
  }
  header .visualizer-wrapper {
    height: auto;
    padding: 0;
    position: static;
  }
  #header-bar {
    border-bottom: none;
    position: relative;
  }
  #header-bar.active #header-stack i {
    background-color: #FF3832;
  }
  #header-bar.active #header-nav {
    display: block;
  }
  #header-stack {
    text-align: right;
    display: block;
  }
  #header-stack i {
    width: 54px;
    padding: 3vmin 1vmin;
    font-size: 1.7em;
    color: white;
    background-color: #EE2E24;
    border-top: 1px solid #F2625B;
    text-align: center;
    cursor: pointer;
  }
  #header-stack i:hover {
    background-color: #FF3832;
  }
  #header-nav {
    width: 100%;
    height: auto;
    padding: 0;
    background-color: white;
    border-top: 1px solid #ebebeb;
    display: block;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1500;
    display: none;
  }
  #header-nav > div, #header-nav > a {
    width: 100%;
    padding: 3vmin 0;
    margin: 0;
    background-color: white;
    font-size: 1.6em;
    text-transform: none;
    display: block;
  }
  #header-nav > div:hover, #header-nav > div:focus, #header-nav > a:hover, #header-nav > a:focus {
    background-color: #ebebeb;
    border-top: none;
  }
  #surface-menu ul {
    margin: 5vmin 0;
    font-size: 1.7em;
    text-align: center;
  }
  #surface-menu ul li {
    margin-bottom: 3vmin;
    padding: 1vmin 4vmin;
    display: inline-flex;
  }
  #surface-menu ul li.active i {
    display: none;
  }
  .product-menu {
    width: 100%;
    max-width: none;
    position: fixed;
    top: 0;
    transform: translateY(100%);
  }
  .product-menu .menu-header {
    font-size: 2.4em;
    display: block;
  }
  #visualizer-controls {
    width: 100%;
  }
  #visualizer-controls > div {
    padding-left: 5vmin;
    font-size: 1.3em;
  }
  #home {
    padding: 4vmin !important;
    font-size: 1.4em;
    width: 100%;
    border-bottom: 1px solid #F2625B;
  }
  #home img {
    width: 7vmin;
  }
  #home:after {
    display: none;
  }
  .close img {
    width: 6.5vmin;
  }
  .product-menu {
    padding: 3vmin;
  }
  .product-menu .menu-content {
    height: auto;
  }
  .product-menu .menu-content p, .product-menu .menu-content select {
    font-size: 1em !important;
  }
  .deck-filter {
    width: 100%;
    float: none;
  }
  #deck-color-family {
    margin: 1vmin 0 3vmin;
  }
  .color-option > div, .railing-color li > div, .light-color li > div {
    width: 10vmin;
    height: 10vmin;
  }
  .railing-selection h3, .railing-selection h5, .lighting-selection h3, .lighting-selection h5 {
    font-size: 1em;
  }
  .rect-thumb div.zoomin,
  .rect-thumb-small div.zoomin,
  .railing-selection .zoomin,
  .light-type-holder div.zoomin {
    font-size: 1.4em;
  }
  #railing-next {
    padding: 1.5vmin 10vmin;
  }
  #railing-zoom-dialog .content {
    flex-direction: column;
  }
  #railing-zoom-dialog .product-details {
    margin: 6vmin 0 2vmin;
  }
  .decking-type-info h4, .rail-info h4, .wall-type-info h4 {
    font-size: 1em;
  }
  .lighting-selection p {
    padding: 3vmin 0;
  }
  .lighting-selection ul {
    padding-bottom: 3vmin;
  }
  .railing-steps {
    font-size: 1em;
  }
  #step-one, #step-two {
    padding-top: 0.5vmin;
  }
}
/* ----------------------------------------------------------------
My Projects
-----------------------------------------------------------------*/
#my-projects > div {
  width: 100% !important;
  margin: 2% 0;
  max-width: 600px;
}

#my-projects .innerFormWrapper {
  box-sizing: border-box;
}

#my-projects .formTitle h2,
#my-projects h2.forgotTitle,
#my-projects h2.forgotTitle label,
#my-projects h2.saveTitle,
#my-projects h2.saveTitle label {
  margin-bottom: 1.5vmin;
  padding: 0;
  color: #161616;
  font-size: 2.2vmin;
  font-weight: bold;
}

#my-projects h2.forgotTitle span {
  color: #5A5A5A;
  font-size: 0.7em;
  font-weight: normal;
  display: block;
}

#my-projects .formBTNS ul {
  margin: 2vmin 0 0;
  padding: 0;
  list-style: none;
}

#my-projects .linkBTNS {
  color: #5A5A5A;
  display: inline-block;
  cursor: pointer;
}

#my-projects .linkBTNS:hover {
  text-decoration: underline;
}

#my-projects .loginBTN,
#my-projects .forgotBTN,
#my-projects .regBTN {
  margin-bottom: 1.5vmin;
}

#my-projects .errors {
  margin-bottom: 2vmin;
}

#my-projects .TitleBar {
  display: flex;
  flex-wrap: wrap;
}

#my-projects .TitleBar h2 {
  flex: 2;
}

#my-projects .titleBarBTNS {
  display: flex;
  align-items: center;
}

#my-projects .titleBarBTNS .buttons {
  margin-right: 1vmin;
  font-size: 0.9em;
  cursor: pointer;
}

#my-projects .titleBarBTNS .buttons:last-child {
  margin-right: 0;
}

#my-projects .closeBTN {
  margin: 0 !important;
  color: white;
  font-size: 0 !important;
  font-weight: 400;
  line-height: 1;
  position: relative;
  cursor: pointer;
}

#my-projects .closeBTN:before {
  content: "X";
  margin-left: 1vmin;
  font-size: 2vh;
  cursor: pointer;
}

#my-projects .myProjectBTNsUL {
  margin: 0;
  padding: 0;
  list-style: none;
}

#my-projects .myProjectBTNsUL li {
  margin-bottom: 4px;
}

#my-projects .projectsForm .projectWrapper {
  width: 100%;
  margin-bottom: 3vmin;
  display: table;
}

#my-projects .projectsForm .projectWrapper > img {
  display: table-cell;
  vertical-align: top;
}

#my-projects .projectsForm .innerWrapper {
  padding-left: 4vmin;
  display: table-cell;
  vertical-align: top;
}

#my-projects .projectsForm .projectTitle {
  margin-bottom: 4px;
  padding-left: 0;
  line-height: 1;
}

#my-projects .projectsForm .projectDate {
  margin-bottom: 1vmin;
  font-size: 0.8em;
}

#uploadOverlay, #modalOverlay, #successOverlay, #congratsOverlay, #confirmOverlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.78);
  display: none;
  z-index: 3010;
}

/*----------------------------------------------------------------
Lightbox
-----------------------------------------------------------------*/
.lightbox {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  display: none;
  z-index: 3005;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.lightbox > div {
  width: 100%;
  max-width: 800px;
  background-color: white;
}

.lightbox button:focus {
  outline: none;
}

.lightbox .header, #my-projects .TitleBar {
  padding: 1vmin 1.5vmin;
  color: white;
  background-color: #ee2e24;
  position: relative;
}

.lightbox .header h2, #my-projects .TitleBar h2 {
  padding: 0;
  color: white;
}

.lightbox .header .close {
  padding: 0;
  background-color: transparent;
  color: white;
  border: none;
  font-weight: bold;
  position: absolute;
  top: 50%;
  right: 1.5vmin;
  transform: translateY(-50%);
}

.lightbox .content, #my-projects .Content {
  padding: 2vmin 1.5vmin;
  border: 1vmin solid white;
  background-color: #E9E8ED;
}

.lightbox .content p {
  margin: 0 0 2vmin;
}

.lightbox .content label, #my-projects .Content label {
  color: #73706d;
  display: block;
}

.lightbox .content label ~ label, #my-projects .Content .lbl ~ .lbl {
  margin-top: 0.8vmin;
}

.lightbox .content input, .lightbox .content textarea, #my-projects .Content input {
  padding: 0.5vmin 0.6vmin;
  border: 1px solid #c2c1c1;
  display: block;
}

.lightbox .content textarea {
  width: 100%;
  min-height: 100px;
}

.lightbox .content .buttons {
  margin-top: 2vmin;
}

.lightbox .content button, #my-projects .loginBTN, #my-projects .forgotBTN, #my-projects .regBTN, #my-projects .changePwdBTN, #my-projects .saveBTN, #my-projects .Use, #my-projects .Remove {
  padding: 0.4vmin 1.6vmin;
  color: white;
  text-transform: uppercase;
  background-color: #ee2e24;
  border: none;
  display: inline-block;
  cursor: pointer;
}

.lightbox .validation-error, #my-projects .errors {
  margin-top: 0.3vmin;
  color: #c31f33;
  font-size: 0.9em;
}

#email-modal .required-notice {
  margin: 1vmin 0;
  font-size: 0.8em;
  text-align: right;
}

#email-modal input[type=text], #my-projects .Content input[type=text], #my-projects .Content input[type=password] {
  width: 100%;
  max-width: 250px;
}

/* ----------------------------------------------------------------
Loading Graphic
-----------------------------------------------------------------*/
.loading-msg {
  position: absolute;
  top: 50% !important;
  left: 50% !important;
  text-align: center;
  transform: translate(-50%, -50%);
}

.loading-msg-inner {
  color: white;
  display: none;
}

@keyframes uil-ellipsis {
  0% {
    transform: scale(0);
    left: 0px;
    opacity: 1;
  }
  12.5% {
    transform: scale(1);
  }
  25% {
    left: 0px;
  }
  37.5% {
    left: 70px;
  }
  50% {
    left: 70px;
  }
  62.5% {
    left: 140px;
  }
  75% {
    left: 140px;
    transform: scale(1);
  }
  87.5% {
    left: 140px;
    transform: scale(0);
    opacity: 1;
  }
  100% {
    left: 140px;
    opacity: 0;
  }
}
.uil-ellipsis-css {
  background: none;
  position: relative;
  width: 200px;
  height: 200px;
}

.uil-ellipsis-css .ib {
  width: 100%;
  height: 100%;
  transform: rotate(0deg);
}

.uil-ellipsis-css .circle {
  width: 60px;
  height: 60px;
  position: absolute;
  top: 70px;
  opacity: 0;
  text-align: center;
  animation: uil-ellipsis 1s linear infinite;
}

.uil-ellipsis-css .circle > div {
  width: 60px;
  height: 60px;
  border-radius: 30px;
  margin: 0px;
}

.uil-ellipsis-css .circle:nth-of-type(2n+1) > div {
  background: #fff;
}

.uil-ellipsis-css.dark .circle:nth-of-type(2n+1) > div {
  background: #403d3d !important;
}

.uil-ellipsis-css .circle:nth-of-type(2n) > div {
  background: #EE2E24;
}

.circle:nth-of-type(1) {
  animation-delay: 0s;
}

.circle:nth-of-type(2) {
  animation-delay: 0.25s;
}

.circle:nth-of-type(3) {
  animation-delay: 0.5s;
}

.circle:nth-of-type(4) {
  animation-delay: 0.75s;
}

/*----------------------------------------------------------------
Modals
-----------------------------------------------------------------*/
.modal-show {
  visibility: visible !important;
  opacity: 1 !important;
}

#MODALS {
  visibility: hidden;
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  opacity: 0;
  z-index: 998;
  transition: all 0.5s;
}

#MODALS div.top-bar {
  width: 100%;
  height: 6%;
  background-color: rgb(35, 93, 123);
}

#MODALS div.close {
  position: absolute;
  right: 1%;
  top: 1.4%;
  width: 1.5vh;
  height: 1.5vh;
  background: url(/img/close.svg);
  cursor: pointer;
}

/*----------------------------------------------------------------
Message Overlays
-----------------------------------------------------------------*/
.message-overlay {
  width: 100%;
  height: 100%;
  background-color: rgba(35, 93, 123, 0.95);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10000;
  display: none;
}

.message-overlay img {
  width: 100%;
  max-width: 600px;
  max-height: 100%;
  display: inline-block;
  vertical-align: middle;
}

ul#product-accordion {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

ul#product-accordion li {
  font-size: 3vmin;
  background-color: lightgray;
  padding: 2% 5%;
  margin-bottom: 1%;
}

ul#product-accordion li:last-child() {
  margin-bottom: 0;
}

#share-options {
  width: 100%;
  padding: 0 2vmin 3vmin;
  background-color: #ffffff;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  transform: translateY(-100%);
  transition: 0.3s transform ease-out;
  z-index: 1501;
  -webkit-tap-highlight-color: transparent;
}
#share-options.active {
  transform: none;
}

#share-options p {
  width: 75%;
  margin: 0 auto;
  text-align: center;
  clear: both;
}

#share-options-inner {
  width: 75%;
  margin: 2.5vmin auto 0;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
}

#share-platforms {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  align-content: space-around;
}

.share-stuff {
  width: 48%;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  cursor: pointer;
  margin: 2vmin 0;
  font-size: 0.8em;
  color: inherit;
  text-decoration: none;
}
.share-stuff img {
  width: 40px;
}

.share-stuff i.fa {
  font-size: 4.2vmin;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait), all and (max-width: 768px) {
  #share-options {
    height: 100%;
    padding: 4vmin;
    position: fixed; /* Scrolling and positioning bugs occuring on iPad when using position: absolute; */
    transform: translateY(100%);
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    z-index: 1500;
  }
  #share-options p {
    padding-top: 4vmin;
  }
  #share-platforms {
    margin-top: 5vmin;
  }
  .share-stuff {
    font-size: 1em;
  }
  .share-stuff i.fa {
    font-size: 2.6em;
  }
  #share-image {
    max-width: 100%;
  }
}
/* ==========================================================================
   TOUR
   ========================================================================== */
#tour {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: none;
  z-index: 5000;
}

.tour-step {
  width: 100%;
  max-width: 340px;
  padding: 10px;
  display: none;
  z-index: 5001;
}
.tour-step .main {
  background-color: white;
  border: 1px solid #E0E0E0;
  border-bottom: none;
  position: relative;
}
.tour-step p {
  margin: 0;
  padding: 3.5vmin 2vmin 4.5vmin;
  font-size: 1.2em;
  font-family: "ProximaNova-Regular";
}

.tour-step .header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.tour-step .header h3 {
  margin: 0;
  padding: 2vmin 0 0 2vmin;
  font-size: 3vmin;
  font-family: "ProximaNova-Semibold";
  font-weight: normal;
}
.tour-step .header .close {
  margin: 4px 4px 0 0;
  padding: 0.5vmin 1vmin;
  color: white;
  background-color: #2A4560;
  font-size: 1.8vmin;
  text-align: center;
  cursor: pointer;
}

.tour-step .footer {
  width: 100%;
  color: white;
  font-size: 1.2em;
  display: flex;
  position: relative;
}
.tour-step .footer div {
  padding: 1.4vmin;
  background-color: #3D3E39;
}
.tour-step .footer button {
  padding: 1.4vmin;
  background-color: #2a4560;
  border: 0;
  flex-grow: 1;
  border-radius: 0;
  color: #fff;
  font-family: "ProximaNova-Regular";
  cursor: pointer;
}
.tour-step .footer button:focus {
  outline: none;
}

.tour-step.arrow-right .main:before {
  content: "";
  width: 0;
  height: 0;
  border-top: 26px solid transparent;
  border-bottom: 26px solid transparent;
  border-left: 21px solid #E0E0E0;
  position: absolute;
  top: 50%;
  left: 100%;
  transform: translateY(-50%);
}

.tour-step.arrow-right .main:after {
  content: "";
  width: 0;
  height: 0;
  border-top: 25px solid transparent;
  border-bottom: 25px solid transparent;
  border-left: 20px solid white;
  position: absolute;
  top: 50%;
  left: 100%;
  transform: translateY(-50%);
}

.tour-step.arrow-up .main:before {
  content: "";
  width: 0;
  height: 0;
  border-left: 26px solid transparent;
  border-right: 26px solid transparent;
  border-bottom: 21px solid #E0E0E0;
  position: absolute;
  left: 50%;
  bottom: 100%;
  transform: translateX(-50%);
}

.tour-step.arrow-up .main:after {
  content: "";
  width: 0;
  height: 0;
  border-left: 25px solid transparent;
  border-right: 25px solid transparent;
  border-bottom: 20px solid white;
  position: absolute;
  left: 50%;
  bottom: 100%;
  transform: translateX(-50%);
}

.tour-step.arrow-down .footer:after {
  content: "";
  width: 0;
  height: 0;
  border-left: 25px solid transparent;
  border-right: 25px solid transparent;
  border-top: 20px solid #2a4560;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
}

.tour-step.arrow-left .main:before {
  content: "";
  width: 0;
  height: 0;
  border-top: 26px solid transparent;
  border-bottom: 26px solid transparent;
  border-right: 21px solid #E0E0E0;
  position: absolute;
  top: 50%;
  right: 100%;
  transform: translateY(-50%);
}

.tour-step.arrow-left .main:after {
  content: "";
  width: 0;
  height: 0;
  border-top: 25px solid transparent;
  border-bottom: 25px solid transparent;
  border-right: 20px solid white;
  position: absolute;
  top: 50%;
  right: 100%;
  transform: translateY(-50%);
}

#applied-products {
  min-width: 25%;
  top: 0;
  z-index: 3000;
  width: 50%;
  display: flex;
  flex-direction: column;
}

#applied-products-block h4 {
  margin: 0 0 2vmin;
  color: #ee2e24;
  font-family: "FuturaBT", Arial, serif;
  font-size: 2.4vmin;
  font-weight: 500;
}

#applied-products.open {
  transform: translateX(0);
}

#applied-products .close {
  padding: 6px 10px;
  color: white;
  border: none;
  background-color: rgb(35, 93, 123);
  font-size: 1.2em;
  position: absolute;
  top: 0;
  right: 100%;
}

#applied-products .close:focus {
  outline: none;
}

#applied-list {
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
#applied-list > ul {
  margin: 0;
  padding: 0;
}

#applied-list li {
  padding: 2% 0;
  list-style: none;
}

#applied-list li h5 {
  color: #948e90;
  font-size: 2.4vmin;
  font-weight: bold;
  margin: 0;
  /* Overwrite Fiberon css. */
  padding: 0;
  border-bottom: none;
  text-align: left;
  line-height: inherit;
}

#applied-list li h6 {
  color: #948e90;
  font-size: 1.6vmin;
  font-weight: 400;
  margin: 0;
  text-transform: uppercase;
}

#applied-list li:first-child {
  padding-top: 0;
}

#applied-list li > div {
  font-size: 1.7vmin;
}

#applied-list li img {
  width: 100px;
  display: inline-block;
  vertical-align: top;
  flex-basis: 100px;
}

#applied-list li .surface-name {
  font-weight: bold;
}

#appliedp-list {
  background-color: #ffffff;
  height: 100%;
  width: 100%;
  position: relative;
}

.product-info {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}
.product-info > div {
  width: 50%;
}

.product-image {
  width: 100%;
  padding-top: 50%;
  background-repeat: no-repeat;
  background-position: center;
}
.product-image.covered {
  background-size: cover;
}
.product-image.contained {
  background-size: contain;
}

.product-specifics {
  padding: 0 4vmin;
}

.product-specifics p {
  color: #161616;
  margin: 0;
  font-size: 1.6vmin;
}

.product-specifics h1 {
  margin-top: 2vmin;
  color: #ee2e24;
  font-size: 5vmin;
  padding: 0;
  line-height: 1;
}
.product-specifics h1 sup {
  font-size: 55%;
  vertical-align: super;
  position: relative;
  top: 0;
  left: 0.1em;
}
.product-specifics h1 sup.dollar-sign {
  font-size: 45%;
  top: 0.05em;
  left: 0;
}
.product-specifics h1 sub {
  font-size: 30%;
  vertical-align: sub;
  position: relative;
  bottom: 0.4em;
  left: -1.6em;
}

#get-a-quote {
  width: 50%;
  padding: 0 4vmin;
  display: flex;
  flex-direction: column;
}

#appliedp-list {
  padding-top: 2vmin;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  overflow-y: auto;
}

.mktoForm .mktoFormCol {
  width: 100% !important;
  float: none !important;
}

.mktoForm .mktoFieldWrap, .mktoForm .mktoLabel, .mktoForm .mktoField, .mktoForm .mktoAsterix {
  float: none !important;
}

.mktoForm .mktoField {
  width: 100% !important;
  max-width: 540px !important;
  display: block;
}

.mktoForm .mktoLabel {
  width: 100% !important;
  font-weight: normal !important;
}

.mktoForm .mktoLabel, .mktoForm .mktoAsterix {
  padding: 0 !important;
  display: inline-block !important;
}

.mktoForm .mktoAsterix {
  margin-right: 3px;
}

.mktoForm .mktoOffset, .mktoForm .mktoGutter {
  display: none !important;
}

.mktoForm .mktoFieldDescriptor {
  margin: 0 0 2vmin !important;
}

.mktoForm .mktoButtonWrap {
  margin-left: 0 !important;
}

.mktoForm .mktoError {
  position: static !important;
}

.mktoForm .mktoErrorArrowWrap {
  display: none !important;
}

.mktoForm .mktoError .mktoErrorMsg {
  max-width: none !important;
  margin: 4px 0 0 !important;
  padding: 0 !important;
  color: #ee2e24 !important;
  background: transparent !important;
  border: none !important;
  text-shadow: none !important;
  box-shadow: none !important;
}

.mktoForm .mktoErrorMsg .mktoErrorDetail {
  display: inline-block !important;
}

.mktoForm .mktoButtonWrap button:after {
  content: url("/img/btn-arrow.png");
  margin-left: 10vmin;
}

.mktoButtonRow > *:after {
  display: none;
}

#quote-wrapper {
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.quote-form {
  width: 100% !important;
  padding: 0 !important;
  font-size: 1.4vmin !important;
}

.quote-form input, .quote-form select, .quote-form textarea {
  height: auto !important;
  padding: 10px 12px !important;
  font-size: 1.3em !important;
  line-height: 1 !important;
  border: 1px solid #858789 !important;
}

.quote-form select[multiple] {
  padding: 10px 0 !important;
}
.quote-form select[multiple] option {
  padding: 0 12px !important;
}

.single-form input, .quote-form input {
  width: 100%;
  max-width: 540px;
}

.single-form {
  margin: 0 0 2vmin;
}

.multi-form input {
  width: 30%;
}

.multi-form {
  width: 100%;
  max-width: 540px;
  margin: 0 0 2vmin;
  display: flex;
  flex-flow: row no-wrap;
  justify-content: space-between;
}
.multi-form select {
  padding: 9px 12px !important;
}

#city-form input {
  width: 100%;
}

#state-form {
  padding: 0 2vmin;
}

#zip-form input {
  width: 100%;
}

span.red {
  color: #ee2e24;
}

.validation-error {
  margin-top: 4px;
  color: #ee2e24;
}

#quote-submit, .mktoForm .mktoButtonWrap {
  margin-top: 2vmin;
}

a {
  color: #ee2e24;
}

#applied-products-block {
  width: 100%;
  height: 100%;
  padding: 5vmin 12vmin 8vmin;
  background-color: white;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2000;
  display: none;
  -webkit-tap-highlight-color: transparent;
}

@media all and (max-width: 1000px) {
  #applied-products-block {
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }
  #appliedp-list {
    height: auto;
    flex-flow: column-reverse nowrap;
  }
  #appliedp-list > div {
    width: 100%;
  }
  #applied-products {
    margin-top: 6vmin;
  }
  #get-a-quote {
    padding: 0;
  }
  #quote-wrapper {
    overflow: hidden;
  }
  .single-form input {
    max-width: none;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait), all and (max-width: 768px) {
  .quote-form {
    font-size: 1em !important;
  }
  #applied-products-block h4 {
    margin-bottom: 5vmin;
    font-size: 1.5em;
  }
  #applied-products {
    display: block;
  }
  #applied-list {
    overflow: hidden;
  }
  #applied-list li {
    padding: 1.5vmin 0;
  }
  #applied-list li h5 {
    font-size: 1.2em;
  }
  .product-specifics {
    margin: 2vmin 0;
    padding: 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .product-specifics > div {
    margin-bottom: 1vmin;
  }
  .product-specifics h6, .product-specifics p {
    font-size: 1em !important;
  }
  .product-specifics h1 {
    margin-top: 0;
    font-size: 8vmin;
  }
  .product-info {
    font-size: 1em !important;
    flex-flow: column nowrap;
  }
  .product-info > div {
    width: 100%;
  }
}
.modal {
  background-color: #FFF;
  z-index: 998;
  display: none;
  box-shadow: 0px 6px 15px 0px rgba(50, 50, 50, 0.5);
  background-repeat: no-repeat;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  padding: 2%;
  box-sizing: border-box;
}

.modal-headline {
  /* width: 525px; */
  /* margin-top: 30px; */
  /* margin-left: -24px; */
  text-align: center;
  /* padding-left: 82px; */
  /* color: #f47604; */
  font-size: 2vmin;
  /* font-family: 'AvenirLTStd-Black'; */
  /* text-transform: uppercase; */
  font-family: "CenturyGothic-Bold";
  color: rgb(35, 93, 123);
}

.modal-close {
  /* height: 25px; */
  /* width: 24px; */
  float: right;
  /* margin-top: -19px; */
  color: rgb(35, 93, 123);
  margin-right: 0px;
  cursor: pointer;
  /* background-color:  rgb(35,93,123); */
  font-family: "CenturyGothic";
  /* line-height: 26px; */
  position: absolute;
  right: 3%;
  top: 2%;
  font-size: 3vmin;
}

.modal-box {
  width: 47vmin;
  height: 32vmin;
  margin: 2vmin auto;
  text-align: center;
  font-family: "CenturyGothic";
}

.upload-box {
  width: 85%;
  height: 60%;
  margin: 0 auto;
  background-color: #EAEAEA;
  /* border-radius: 3px; */
  border: dashed 2px #D6D6D6;
  color: #575757;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 300;
  /* display: none; */
  padding: 20%;
  box-sizing: border-box;
}

.upload-box p {
  font-size: 1.5vmin;
}

.upload-box input[type=file] {
  display: none;
}

.modal-pic {
  /* width: 450px; */
  /* height: 280px; */
  margin: 0 auto;
  background-color: #EAEAEA;
  border-radius: 3px;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 200;
  /* display: none; */
  clear: both;
  overflow: hidden;
  width: 85%;
  height: 60%;
}

.trash {
  /* height: 42px; */
  /* width: 50px; */
  background-color: #646464;
  color: #FFF;
  /* float: right; */
  font-size: 4vmin;
  /* padding-top: 8px; */
  cursor: pointer;
  border-top-right-radius: 3px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 4;
  padding: 1vmin;
}

.modal-pic #imgToUpload {
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
}

.modal-label {
  float: left;
  font-size: 16px;
  font-weight: bold;
  color: #575757;
  margin: 5px 0 0 55px;
  /* font-weight: 900; */
  font-family: "CenturyGothic";
}

.modal-input {
  padding: 10px;
  border: 1px solid #B1B1B1;
  border-radius: 5px;
  border-image: none;
  font-size: 20px;
  color: #323031;
  box-shadow: 0px 0px 0px 0px rgba(42, 42, 42, 0.75);
  margin: 10px 0 0 50px;
  width: 440px;
  float: left;
}

.project-button {
  /* cursor: pointer; */
  /* background-color: #f01e00; */
  -moz-border-radius: 5px;
  /* border-radius: 5px; */
  /* border: 1px solid #fff; */
  /* cursor: pointer; */
  /* color: #fff; */
  /* box-sizing: border-box; */
  display: block;
  text-align: center;
  /* line-height: 32px; */
  /* width: 235px; */
  /* margin: 26px auto 0 auto; */
  /* font-family: 'CenturyGothic'; */
  /* letter-spacing: 0.05em; */
  /* height: 52px; */
  font-size: 20px;
}

.project-button i {
  margin-left: 5px;
}

.uploaderBTN {
  background-color: #fff;
  color: #fff;
  /* border: 1px solid #f5980c; */
  text-align: center;
  cursor: pointer;
  -webkit-border-radius: 9px;
  -moz-border-radius: 9px;
  border-radius: 1px;
  box-sizing: border-box;
  padding: 1.5vmin;
  font-weight: bold;
  background-color: rgb(35, 93, 123);
  font-family: "CenturyGothic";
  letter-spacing: 0.05em;
  text-transform: uppercase;
  font-size: 1.5vmin;
}

/*overlay*/
#overlay {
  position: fixed;
  top: 0;
  left: 0;
  background-color: #000;
  width: 100%;
  height: 100%;
  display: none;
  position: absolute;
  z-index: 997;
}

#closemodal {
  float: right;
  cursor: pointer;
  /* letter-spacing: 3px; */
  margin-top: 15px;
  font-size: 1.25vh;
  margin-right: 16px;
  color: #353334;
  font-weight: 900;
}

#closemodal i {
  color: rgb(35, 93, 123);
  margin-right: 5px;
}

#upload-container {
  width: 75%;
  height: 60%;
  /* margin-left: auto; */
  /* margin-right: auto; */
  position: absolute;
  background-color: #ffffff;
  display: block;
  display: none;
  top: 0;
  left: 0;
  margin: auto;
  right: 0;
  bottom: 0;
  max-width: 1155px;
}

.upload-left-head {
  font-size: 2.75vmin;
  color: rgb(35, 93, 123);
  float: left;
  font-family: "CenturyGothic-Bold";
  text-transform: uppercase;
}

.upload-box-left {
  /* height: 100%; */
  width: 36%;
  /* padding: 75px 0 0 75px; */
  background-color: #fff;
  float: left;
  font-family: "CenturyGothic";
  padding: 5% 0% 5% 5%;
  box-sizing: border-box;
  /* background-color: #eee; */
}

.upload-box-right {
  /* height: 100%; */
  width: 64%;
  /* margin-left: 1%; */
  float: right;
  box-sizing: border-box;
  /* background-color: #ff0; */
  padding-left: 7%;
}

.upload-left-text {
  font-size: 1.35vmin;
  font-weight: bold;
  float: left;
  /* padding-right: 16px; */
  margin-top: 20px;
  line-height: 2vh;
  color: rgb(88, 88, 88);
}

.upload-left-text i {
  color: rgb(35, 93, 123);
  margin-left: 2%;
  margin-right: 1%;
}

.upload-button-box {
  /* width: 280px; */
  /* padding: 65px 0 0 0; */
  float: left;
  /* background-color: #ff0; */
  padding-top: 10%;
  width: 100%;
  text-align: center;
}

.photo-good {
  /* height: 64%; */
  width: 40%;
  margin: 12% 3% 0 0;
  float: left;
  /* background-image: url(/img/good.jpg); */
  position: relative;
  box-sizing: border-box;
  background-repeat: no-repeat;
}

.photo-good img {
  width: 100%;
  height: 100%;
  /* background-repeat: no-repeat; */
}

.photo-good.bad {
  /* background-image: url(/img/bad.jpg); */
}

.photo-text {
  /* height: 100px; */
  width: 85%;
  margin-top: 2%;
  float: left;
  font-family: "CenturyGothic";
}

.star {
  color: #e11c00;
  float: left;
  width: 10px;
  margin-right: 10px;
}

.star-text {
  float: right;
  /* width: 475px; */
  font-size: 1.2vh;
  font-weight: bold;
  line-height: 2vh;
  font-family: "CenturyGothic";
  color: rgb(88, 88, 88);
}

.star-text i {
  color: rgb(35, 93, 123);
  margin-right: 1%;
}

.photo-box-bar {
  /* height: 45%; */
  width: 100%;
  /*background-image: url(/img/black.png);*/
  background-color: rgba(0, 0, 0, 0.78);
  color: #fff;
  font-size: 2vh;
  font-weight: bold;
  /* padding: 18px 0 0 15px; */
  box-sizing: border-box;
  padding: 4%;
  position: absolute;
  z-index: 5000;
  top: 0;
}

.btn-upload {
  font-family: "CenturyGothic";
  color: #ffffff;
  font-size: 2vmin;
  padding: 3%;
  background-color: rgb(88, 88, 88);
  cursor: pointer;
  box-sizing: border-box;
  width: 100%;
  margin: 0 auto;
  display: block;
}

#im-container {
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  /* display: none; */
  background-color: #ffffff;
  overflow-y: auto;
}

#im-box {
  width: 65%;
  height: 100%;
  position: absolute;
  z-index: 60;
  background-color: #fff;
  right: 0;
}

#im-view {
  width: 100%;
  height: 100%;
  background-color: #ededed;
  box-sizing: border-box;
}

.im-view-top {
  width: 100%;
  padding-top: 0px;
  background-color: #fff;
}

.ctr {
  width: auto;
  text-align: center;
  margin-bottom: 0.5%;
  margin-top: 0.5%;
}

.im-overlay {
  position: absolute;
  left: 0px;
  z-index: 500;
  position: relative;
}

#cropPhoto {
  width: 100%;
}

#map-box {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 10;
}

#map-view {
  width: 626px;
  height: 100%;
  float: right;
}

.map-view-top {
  width: 100%;
  background-color: #ffffff;
  box-sizing: border-box;
  margin-bottom: 0.5%;
  margin-top: 0.5%;
}

.ctr-map {
  width: 100%;
  text-align: center;
}

.btnim.active {
  color: rgb(255, 255, 255);
  cursor: pointer;
  text-decoration: none !important;
}

.btnim {
  text-align: center;
  -moz-border-radius: 5px;
  box-sizing: border-box;
  border: 1px solid #000033;
  display: inline-block;
  width: auto;
  background-color: #000033;
  font-size: 1.5vh;
  color: rgb(255, 255, 255);
  cursor: pointer;
  text-transform: uppercase;
  padding: 1% 3% 1%;
}

.mapping-box {
  width: 588px;
  border: solid 2px #fff;
  background-color: #fff;
  margin: 0 auto;
}

.mapping-nav {
  width: 100%;
  height: auto;
  background-color: #fff;
  margin-top: 0.75%;
}

.mapping-nav-left {
  width: 57%;
  float: left;
  padding-left: 0.5%;
}

.mapping-nav-right {
  width: 40%;
  display: inline-block;
  float: right;
  text-align: right;
}

#im-tools {
  width: 35%;
  height: 100%;
  background-color: #dfdfdf;
  position: relative;
  z-index: 10;
}

#im-crop {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: #ffffff;
  left: 0;
  right: 0;
  top: 0;
  z-index: 22230;
}

/*VIDEO HELP DIV - WILL SHOW LATER */
.crop-fullscreen {
  width: 100%;
  border: 3px solid #999999;
  text-align: center;
  color: #FFF;
  text-decoration: underline;
  cursor: pointer;
  text-decoration: none;
  background-repeat: no-repeat;
}

.crop-fullscreen img {
  width: 100%;
  height: 100%;
}

.crop-caption {
  background-color: rgba(0, 0, 0, 0.75);
  font-size: 1.5vh;
  padding: 3% 2% 2%;
  box-sizing: border-box;
  margin-top: -2%;
}

.crop-box {
  width: 80%;
  box-sizing: border-box;
  margin: 10% auto 0 auto;
}

.crop-box h2 {
  color: #000033;
  font-size: 2.5vh;
  text-align: center;
  text-transform: uppercase;
  line-height: 6vh;
  font-weight: 700;
}

.crop-box p {
  text-align: center;
  font-size: 1.75vh;
  line-height: 2.25vh;
  color: #58585a;
  margin: 4%;
  text-align: left;
}

.perspective-fullscreen {
  background-image: url(/img/vid/perspective.gif);
  border: 3px solid #999999;
  text-align: center;
  color: #FFF;
  text-decoration: underline;
  cursor: pointer;
  text-decoration: none;
  background-repeat: no-repeat;
  margin: 0 auto;
  width: 85%;
  height: 19vw;
  position: relative;
  background-size: cover;
  -o-object-fit: cover;
  object-fit: cover;
}

.perspective-caption {
  height: 23px;
  width: 178px;
  background-image: url(/img/black.png);
  margin: 98px auto 0 auto;
  border-radius: 0 0 5px 5px;
}

.perspective-box {
  width: 200px;
  height: 425px;
  margin: 95px auto 0 auto;
}

.perspective-box h2 {
  color: #000033;
  font-size: 22px;
  font-weight: bold;
  line-height: 26pt;
  text-align: center;
  text-transform: uppercase;
}

.perspective-box p {
  text-align: left;
  font-size: 13px;
  line-height: 17px;
  padding-left: 5px;
  font-weight: 500;
  color: #58585a;
}

#im-floor {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: #dfdfdf;
  background-color: #ffffff;
  left: 0;
  right: 0;
  top: 0;
  border-radius: 0 5px 5px 0;
  z-index: 220;
  display: none;
}

.floor-area ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.floor-area ul li.active {
  color: #483698;
}

.divider {
  width: 98%;
  height: 10px;
  border-top: dashed 1px #595959;
  margin-top: 6px;
}

.step {
  text-align: center;
  color: #575757;
  font-size: 2vh;
}

.area {
  text-align: center;
  font-size: 22px;
  color: #483698;
  margin-top: 4px;
  font-weight: 900;
}

.choose-tool {
  width: 100%;
  height: 7vmin;
  margin: 0 auto;
  background-color: #fff;
  margin-bottom: 3%;
}

.choose-tool ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.choose-tool ul li.active {
  background-color: #000033;
  color: #FFF;
}

.choose-tool ul li.middle {
  width: 25%;
  border-left: solid 1px #DBDBDB;
}

.choose-tool ul li {
  float: left;
  /* height: 74px; */
  width: 25%;
  display: inline;
  background-color: #575757;
  color: #c3c3c3;
  font-size: 1.5vmin;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  padding-top: 1vmin;
  line-height: 2vmin;
  box-sizing: border-box;
  /* margin-bottom: 1vmin; */
  padding-bottom: 0.75vmin;
}

.choose-tool ul li i {
  font-size: 4vmin;
}

/*VIDEO HELP DIV - WILL SHOW LATER */
.tool-fullscreen {
  border: 3px solid #999999;
  text-align: center;
  color: #FFF;
  text-decoration: underline;
  cursor: pointer;
  text-decoration: none;
  background-repeat: no-repeat;
  margin: 0 auto;
  width: 85%;
  height: 19vw;
  position: relative;
}

.tool-caption {
  background-color: rgba(0, 0, 0, 0.75);
  font-size: 1.5vh;
  padding: 3% 2% 2%;
  margin-top: -2%;
  position: absolute;
  width: 100%;
  box-sizing: border-box;
  bottom: 0px;
}

.tool-text {
  font-size: 1.5vh;
  text-align: left;
  color: #58585a;
  width: 90%;
  padding-left: 10%;
  box-sizing: border-box;
  margin-top: 3%;
}

div.floor-tools div.tool-text ol, div.floor-tools div.tool-text p {
  display: none;
}

div.floor-tools div.tool-text ol {
  padding: 13px;
}

div.floor-tools.outline div.tool-text .useDraw {
  display: block;
}

div.floor-tools.paint div.tool-text .usePaint {
  display: block;
}

div.floor-tools.erase div.tool-text .useErase {
  display: block;
}

div.floor-tools.fill div.tool-text .useWand {
  display: block;
}

div.floor-tools.outline .tool-fullscreen {
  background-image: url(/img/vid/outline.gif);
  background-size: cover;
  -o-object-fit: cover;
  object-fit: cover;
}

div.floor-tools.paint .tool-fullscreen {
  background-image: url(/img/vid/paint.gif);
  background-size: cover;
  -o-object-fit: cover;
  object-fit: cover;
}

div.floor-tools.erase .tool-fullscreen {
  background-image: url(/img/vid/erase.gif);
  background-size: cover;
  -o-object-fit: cover;
  object-fit: cover;
}

div.floor-tools.fill .tool-fullscreen {
  background-image: url(/img/vid/Fill.gif);
  background-size: cover;
  -o-object-fit: cover;
  object-fit: cover;
}

.tool-text li {
  margin-bottom: 10px;
  color: #58585a;
  font-size: 1.5vh;
}

div.floor-tools div.tool-text ol {
  padding: 13px;
}

.tool-sc {
  width: 100%;
}

#im-angle {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: #ffffff;
  left: 0;
  right: 0;
  top: 0;
  border-radius: 0 5px 5px 0;
  z-index: 210;
  display: none;
}

.floor-area {
  width: 100%;
}

.floor-area ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.floor-area ul li.selected {
  color: #999;
}

.floor-area ul li.active {
  color: #008AFF;
}

.floor-area ul li {
  font-size: 1.75vh;
  cursor: pointer;
  display: inline-block;
  color: #999;
  text-align: center;
  cursor: pointer;
  font-weight: 700;
  padding: 1% 5%;
  width: 48%;
}

.area {
  text-align: center;
  font-size: 2vh;
  color: #000033;
  text-transform: Uppercase;
}

.floor-tools {
  width: 85%;
  border: solid 2px #C8C8C8;
  background-color: #fff;
  margin-top: 1%;
  margin-left: 7%;
  overflow-y: auto;
}

[class^=slider] {
  display: inline-block;
  margin-bottom: 15px;
}

.width {
  width: 13%;
  float: left;
  background-color: #FFF;
  font-weight: bold;
  font-size: 1.25vh;
  line-height: 2vh;
  margin-left: 3%;
}

[class^=slider] {
  display: inline-block;
  margin-bottom: 15px;
}

.slidercontain {
  background-color: #ffffff;
  width: 90%;
  margin-left: 3%;
  margin-bottom: 0 !important;
  height: 20px !important;
}

.slider-center {
  width: 50%;
  height: 20px;
  background-color: #FFF;
  float: left;
}

.slider-volume {
  width: 60% !important;
}

.slider-center input.value {
  position: relative;
  right: 2.5%;
  float: right;
  text-align: center;
  font-size: 1.25vmin;
}

.feet {
  /*float: left;*/
  font-weight: bold;
  font-size: 1.5vh;
  line-height: 2vh;
  color: #888;
  padding-left: 0;
}

.tool-ss {
  height: 50px;
  width: 100%;
  margin: 2% auto 0 auto;
}

#overlay {
  position: fixed;
  top: 0;
  left: 0;
  background-color: #000;
  width: 100%;
  height: 100%;
  display: none;
  position: absolute;
  z-index: 997;
}

#modPop {
  width: 30%;
  background-color: #FFF;
  border-top: solid 9px #000033;
  position: absolute;
  padding: 3vmin;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 999;
  box-shadow: 0px 6px 15px 0px rgba(50, 50, 50, 0.5);
  overflow: hidden;
  overflow-y: auto;
  display: none;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

#modWarning {
  background-color: #FFF;
  border-top: solid 9px #000033;
  position: absolute;
  padding: 2vmin;
  z-index: 999;
  box-shadow: 0px 6px 15px 0px rgba(50, 50, 50, 0.5);
  overflow: hidden;
  overflow-y: auto;
  display: none;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

#modWarning-headline {
  color: #000033;
  text-align: center;
  font-size: 2.5vmin;
}

#modWarning-text {
  font-size: 1.75vmin;
  text-align: center;
  margin-top: 5px;
  margin-bottom: 15px;
}

.modWarning-button {
  cursor: pointer;
  padding: 1vmin 2vmin;
  background-color: #fff;
  margin: 2vmin;
  border: solid 2px #000033;
  text-align: center;
  color: #000033;
  line-height: 2vmin;
  /* padding-top: 4px; */
  font-size: 2vmin;
}

.modWarning-button:hover {
  background-color: #585858;
  border: solid 2px #585858;
  text-align: center;
  color: #fff;
}

#modPop-headline {
  text-align: left;
  font-weight: bold;
  color: #000033;
  float: left;
  font-size: 2.5vmin;
}

#modPop-newsurfacelist {
  line-height: 6vmin;
  color: #000;
  margin-bottom: 3vmin;
  font-size: 2vmin;
}

.modPop-close {
  height: 25px;
  width: 50px;
  color: #9d9d9d;
  cursor: pointer;
  position: absolute;
  top: 10px;
  left: 402px;
  display: none;
}

.btn-modPop {
  color: #ffffff;
  font-size: 1.5vmin;
  border: solid #fff 1px;
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  width: auto;
  margin: 0 5px 0 1px;
  text-transform: uppercase;
  background: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJod…EiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+");
  line-height: 3vmin;
  background-color: #000033;
  padding: 0.5vmin 1vmin;
}

.btn-modPop.dark {
  background: #575757;
  color: #fff;
}

.btn-modPop.grey {
  background: #595959;
  color: #fff;
}

.mp {
  color: #000;
  font-size: 16px;
  line-height: 50px;
  font-weight: 900;
  display: inline-block;
  margin-right: 7px;
  vertical-align: middle;
}

.mp.grey {
  color: #999;
}

.modPop-rule-sm {
  border-bottom: 2px solid #000;
  width: 276px;
  margin: 19px auto;
}

.btnsave-purple {
  font-size: 14px;
  padding: 10px 13px;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  background-color: #fff;
  color: #FFF;
  text-align: center;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  box-sizing: border-box;
  background-color: #000033;
  margin-top: 7px;
  /*min-width: 230px;*/
  text-transform: uppercase;
  border-radius: 0px;
  box-sizing: border-box;
  font-size: 1.75vh;
  padding: 3% 5%;
  width: 85.5%;
  margin-left: 7%;
}

.btnsave-purple.active {
  cursor: pointer;
  opacity: 1;
  filter: alpha(opacity=100);
  margin-right: 10px;
  background-color: #000033;
  color: #fff;
  text-transform: uppercase;
  border-radius: 0px;
  box-sizing: border-box;
  font-size: 1.75vh;
  padding: 3% 5%;
  width: 85.5%;
  margin-left: 7%;
}

.btnsave-purple.active.green {
  background-color: #2A6496;
}

.btnsave-grey {
  background-color: #2A6496;
  color: #fff;
  font-size: 14px;
  padding: 10px 26px;
  text-decoration: none;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  text-transform: uppercase;
  box-sizing: border-box;
  font-size: 1.75vh;
  padding: 3% 5%;
  width: 85.5%;
  margin-left: 7%;
}

.btnim-purple {
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  text-align: center;
  box-sizing: border-box;
  color: #fff;
  display: inline-block;
  width: auto;
  background-color: #000033;
  text-transform: uppercase;
  box-sizing: border-box;
  font-size: 1.75vh;
  padding: 3% 5%;
  width: 100%;
}

.btnmapping {
  color: #666666;
  display: inline-block;
  margin-right: 2%;
  cursor: pointer;
  text-align: right;
  font-size: 1.75vh;
}

.output {
  color: #000;
  font-size: 14px;
  font-weight: bold;
  padding-top: 1px;
  margin-left: 5px;
  vertical-align: top;
  border: solid 1px #999999;
  border-radius: 5px;
  padding: 4px;
}

.currentSurfaceTitle {
  display: none;
}

tool-fullscreen {
  height: 119px;
  width: 141px;
  background-image: url(/img/fullscreen.jpg);
  margin: 10px 0 10px 70px;
  border-radius: 5px;
  border: 1px solid #483698;
  text-align: center;
  color: #ffffff;
  font-weight: bold;
  text-decoration: underline;
  line-height: 23px;
  cursor: pointer;
}

.vid-modal {
  background-color: #FFF;
  border-radius: 14px;
  z-index: 1100;
  display: none;
  box-shadow: 0px 6px 15px 0px rgba(50, 50, 50, 0.5);
  background-repeat: no-repeat;
  background-repeat: repeat-x;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  padding: 2vmin;
}

.vid-modal.outline {
  display: none;
}

.vid-modal.paint {
  display: none;
}

.vid-modal.wand {
  display: none;
}

.vid-modal.erase {
  display: none;
}

.vid-modal.perspective {
  display: none;
}

.vid-modal-headline {
  /* width: 525px; */
  /* margin-top: 30px; */
  /* margin-left: 26px; */
  text-align: center;
  /* padding-left: 82px; */
  font-size: 2.5vmin;
  text-transform: uppercase;
  font-weight: 700;
  color: #000033;
}

.vid-modal-close {
  color: #000033;
  cursor: pointer;
  font-size: 3vmin;
  position: absolute;
  right: 2vmin;
  top: 1vmin;
}

.vid-modal-box {
  width: 78vmin;
  height: 52vmin;
  margin: 21px auto;
  text-align: center;
  display: block;
  background-image: url(/img/vid/Crop.gif);
  background-size: cover;
  -o-object-fit: cover;
  object-fit: cover;
}

.vid-modal-box.outline {
  background-image: url(/img/vid/outline.gif);
  background-size: cover;
  -o-object-fit: cover;
  object-fit: cover;
}

.vid-modal-box.paint {
  background-image: url(/img/vid/paint.gif);
  background-size: cover;
  -o-object-fit: cover;
  object-fit: cover;
}

.vid-modal-box.wand {
  background-image: url(/img/vid/fill.gif);
  background-size: cover;
  -o-object-fit: cover;
  object-fit: cover;
}

.vid-modal-box.erase {
  background-image: url(/img/vid/erase.gif);
  background-size: cover;
  -o-object-fit: cover;
  object-fit: cover;
}

.vid-modal-box.perspective {
  background-image: url(/img/vid/perspective.gif);
  background-size: cover;
  -o-object-fit: cover;
  object-fit: cover;
}

.vid-text {
  color: #58585a;
  font-weight: bold;
  display: block;
  text-align: center;
  font-size: 1.5vmin;
}

/*overlay*/
#vid-overlay {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.76);
  width: 100%;
  height: 100%;
  display: none;
  position: absolute;
  z-index: 1099;
}

/* ChamModal  */
.chamModal {
  background: url("/ChamWebTools/JS/ChamModal/1/overlay.png") repeat left top;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  display: none;
  z-index: 999;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: 0;
  padding: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  -moz-user-drag: none;
  user-drag: none;
}

.chamModal .modalInner {
  position: absolute;
  /*min-width: 25%;*/
  background: #e5e5e5;
  border: 2px solid #cfcfcf;
  left: 50%;
  top: 50%;
  -ms-transform: translate(-50%, -50%); /* IE 9 */
  -webkit-transform: translate(-50%, -50%); /* Safari */
  transform: translate(-50%, -50%);
}

.chamModal .modalInner.Draggable {
  vertical-align: top;
  margin: 0 auto 0 auto;
  max-width: 100%;
  max-height: 100%;
  /*min-width: 25%;*/
  background: #e5e5e5;
  border: 2px solid #cfcfcf;
  margin-left: auto;
  margin-right: auto;
  color: #555;
  left: 50%;
  top: 30%;
  -ms-transform: translate(-50%, -50%); /* IE 9 */
  -webkit-transform: translate(-50%, -50%); /* Safari */
  transform: translate(-50%, -50%);
  border-radius: 5px;
}

.chamModal .modalTitleBar {
  width: 100%;
  height: 40px;
  line-height: 40px;
  border: none;
  background: none;
  text-align: right;
  z-index: 20;
}

.chamModal .modalTitleBar .title, .chamModal .modalTitleBar .closeBTN {
  display: inline-block;
  zoom: 1;
  *display: inline;
  vertical-align: top;
  position: relative;
  color: #fff;
  padding: 0;
  margin: 0;
}

.chamModal .modalTitleBar .title {
  font-size: 20px;
  text-align: left;
  width: auto;
  float: left;
  text-indent: 10px;
}

.chamModal .modalTitleBar .closeBTN {
  font-size: 30px !important;
  /* right: 6px; */
  cursor: pointer;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  color: #999;
  top: -2px;
}

.chamModal .modalContent {
  padding: 10px;
}

.chamModal .modalContent .button {
  cursor: pointer;
}

.chamModal .modalContent .question {
  margin-bottom: 12px;
  font-size: 14px;
}

.rightPadding {
  float: right;
  padding-top: 2px;
}

.newSurface {
  font-size: 1.5vmin;
  color: #666666;
  text-transform: none !important;
}

.gone {
  font-size: 3vmin;
  position: absolute;
  right: 15px;
  top: 10px;
  cursor: pointer;
}

.goright i {
  padding-left: 6px;
  font-size: 1.75vh;
}

.upload-box-left {
  height: 412px;
  width: 280px;
  padding: 75px 0 0 75px;
  background-color: #fff;
  float: left;
}

#panTip {
  width: 100%;
  height: auto;
  display: block;
  padding: 0.5%;
  color: #575757;
  font-size: 1.35vh;
  box-sizing: border-box;
  margin-top: 0.75%;
}

.panIcon {
  float: left;
  padding-right: 1%;
}

.panIcon i {
  font-size: 4vh;
  color: #575757;
}

.widthSelect {
  position: absolute;
  width: 140px !important;
  height: 148px;
  z-index: 999;
  border: 1px solid #fff;
  display: none;
  background-color: #383b43;
  margin-top: 7px;
  margin-left: 0px;
  clear: both;
}

.widthSelect li {
  height: 49px !important;
  padding: 0px !important;
  clear: both;
}

.slider-box {
  margin: 0 auto !important;
  width: 100%;
  padding-left: 6%;
  box-sizing: border-box;
}

/*THIS WILL MAKE IM IMAGE RESPONSIVE */
.im-box {
  width: 100% !important;
}

#im-view, .im-overlay {
  width: 100% !important;
}

#map-view {
  width: 62% !important;
  margin-right: 2%;
}

#mapping-box {
  width: 100% !important;
}

#im-view {
  width: 100% !important;
  overflow-y: auto;
  overflow-x: hidden;
}

.imap, #im-tools {
  width: 35% !important;
}

#viz, #viz .ImageMapper, #viz .ImageMapper canvas {
  width: 100% !important;
}

/* , #viz .ImageMapper canvas */
/*END THIS WILL MAKE IM IMAGE RESPONSIVE */
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (orientation: landscape) {
  /* iPhone 6+ landscape */
  .tool-text {
    font-size: 2.1vh;
    line-height: 2.2vh;
    margin-top: 2%;
  }
  .tool-text li {
    font-size: 1.75vh;
    margin-bottom: 2px;
  }
  div.floor-tools div.tool-text ol {
    padding: 0px;
  }
  #panTip {
    font-size: 1.85vh;
  }
  .panIcon i {
    font-size: 7vh;
  }
  .slider-center {
    /*width: 73%;*/
  }
  .perspective-fullscreen {
    margin-top: -6%;
  }
  .slider-center {
    /*width: 69%;*/
  }
  .slider-center input.value {
    /*top: -39px !important;*/
  }
  .perspective-fullscreen {
    width: 44%;
    height: 9vw;
  }
}
/* iPhone 6 landscape */
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (orientation: landscape) {
  .slider-center input.value {
    /*top: -35px !important;*/
  }
  .perspective-fullscreen {
    width: 44%;
    height: 9vw;
  }
}
/* ipad in landscape */
@media only screen and (min-device-width: 961px) and (max-device-width: 1024px) and (orientation: landscape) {
  .slider-center {
    /* width: 73%; */
  }
}
/* 7" Tablet */
@media only screen and (min-device-width: 600px) and (max-device-width: 960px) and (orientation: landscape) {
  .slider-center {
    /*width: 73%;*/
  }
  .width {
    font-size: 1.25vh;
    line-height: 3.5vh;
  }
  .slider-center input.value {
    /*position: relative;
    top: 0px;
    right: 1.5%;
    float: right;
    text-align: center;
    font-size: 1.5vh;*/
  }
  .feet {
    line-height: 3vh;
  }
}
/* Galaxy 10 Landscape */
@media only screen and (min-device-width: 1025px) and (max-device-width: 1280px) and (orientation: landscape) {
  .slider-box {
    /*padding-left: 3%;*/
  }
  .slider-center {
    /*width: 73%;*/
  }
}
/* iPhone 5 Portrait */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  .feet {
    padding-left: 0;
    float: right;
  }
  .slider-center {
    width: 75%;
    height: 20px;
    background-color: #FFF;
    float: left;
  }
}
/* iPhone 6 Portrait */
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  .feet {
    padding-left: 37%;
  }
}
/* iphone 6+ Portrait */
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {
  .feet {
    padding-left: 37%;
  }
}
/* MISC STYLES
   ========================================================================== */
.clearfix {
  display: inline-block;
}

.clearfix:after {
  content: " ";
  display: block;
  height: 0;
  clear: both;
  overflow: hidden;
  visibility: hidden;
}

.clearfix {
  display: block;
}

/* Simple Slider
   ========================================================================== */
.slider {
  width: 300px;
}

.slider > .dragger {
  background: #8DCA09;
  background: -webkit-linear-gradient(top, #8DCA09, #72A307);
  background: -moz-linear-gradient(top, #8DCA09, #72A307);
  background: linear-gradient(top, #8DCA09, #72A307);
  -webkit-box-shadow: inset 0 2px 2px rgba(255, 255, 255, 0.5), 0 2px 8px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: inset 0 2px 2px rgba(255, 255, 255, 0.5), 0 2px 8px rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 2px 2px rgba(255, 255, 255, 0.5), 0 2px 8px rgba(0, 0, 0, 0.2);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  border: 1px solid #496805;
  width: 16px;
  height: 16px;
}

.slider > .dragger:hover {
  background: -webkit-linear-gradient(top, #8DCA09, #8DCA09);
}

.slider > .track, .slider > .highlight-track {
  background: #ccc;
  background: -webkit-linear-gradient(top, #bbb, #ddd);
  background: -moz-linear-gradient(top, #bbb, #ddd);
  background: linear-gradient(top, #bbb, #ddd);
  -webkit-box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  border: 1px solid #aaa;
  height: 4px;
}

.slider > .highlight-track {
  background-color: #8DCA09;
  background: -webkit-linear-gradient(top, #8DCA09, #72A307);
  background: -moz-linear-gradient(top, #8DCA09, #72A307);
  background: linear-gradient(top, #8DCA09, #72A307);
  border-color: #496805;
}

.slider-volume {
  width: 80%;
}

.slider-volume > .dragger {
  width: 18px;
  height: 18px;
  margin: 0 auto;
  border: 1px solid rgba(255, 255, 255, 0.6);
  /*-moz-box-shadow: 0 0px 2px 1px rgba(0,0,0,0.5), 0 2px 5px 2px rgba(0,0,0,0.2);
  -webkit-box-shadow: 0 0px 2px 1px rgba(0,0,0,0.5), 0 2px 5px 2px rgba(0,0,0,0.2);
  box-shadow: 0 0px 2px 1px rgba(0,0,0,0.5), 0 2px 5px 2px rgba(0,0,0,0.2);*/
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  background: #8F0100;
  /*background: -moz-linear-gradient(90deg, rgba(180,180,180,1) 20%, rgba(230,230,230,1) 50%, rgba(180,180,180,1) 80%);
  background:	-webkit-radial-gradient(  50%   0%,  12% 50%, hsla(0,0%,100%,1) 0%, hsla(0,0%,100%,0) 100%),
              -webkit-radial-gradient(  50% 100%, 12% 50%, hsla(0,0%,100%,.6) 0%, hsla(0,0%,100%,0) 100%),
              -webkit-radial-gradient(	50% 50%, 200% 50%, hsla(0,0%,90%,1) 5%, hsla(0,0%,85%,1) 30%, hsla(0,0%,60%,1) 100%);*/
}

.slider-volume > .track, .slider-volume > .highlight-track {
  height: 8px;
  background: #FFFFFF;
  border: 1px solid #999999;
  /*-moz-box-shadow: inset 0 2px 5px 1px rgba(0,0,0,0.15), 0 1px 0px 0px rgba(230,230,230,0.9), inset 0 0 1px 1px rgba(0,0,0,0.2);
  -webkit-box-shadow: inset 0 2px 5px 1px rgba(0,0,0,0.15), 0 1px 0px 0px rgba(230,230,230,0.9), inset 0 0 1px 1px rgba(0,0,0,0.2);
  box-shadow: inset 0 2px 5px 1px rgba(0,0,0,0.15), 0 1px 0px 0px rgba(230,230,230,0.9), inset 0 0 1px 1px rgba(0,0,0,0.2);*/
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

.slider-volume > .highlight-track {
  background-color: #c5c5c5;
  background: -moz-linear-gradient(top, #c5c5c5, #a2a2a2);
  background: -webkit-linear-gradient(top, #c5c5c5, #a2a2a2);
  background: linear-gradient(top, #c5c5c5, #a2a2a2);
}