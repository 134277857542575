﻿ul#product-accordion {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
ul#product-accordion li {
  font-size: 3vmin;
  background-color: lightgray;
  padding: 2% 5%;
  margin-bottom: 1%;
}
ul#product-accordion li:last-child() {
  margin-bottom: 0;
}
