html {
  height: auto !important;
}

.fiberon {
  position: relative;
  z-index: 1600;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait), all and (max-width: 768px) {
  body.no-overflow {
    overflow: hidden;
  }
}