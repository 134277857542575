﻿#im-container {
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  /* display: none; */
  background-color: #ffffff;
  overflow-y: auto;
}

#im-box {
  width: 65%;
  height: 100%;
  position: absolute;
  z-index: 60;
  background-color: #fff;
  right: 0;
}


#im-view {
  width: 100%;
  height: 100%;
  background-color: #ededed;
  box-sizing: border-box;
}

.im-view-top {
  width: 100%;
  padding-top: 0px;
  background-color: #fff;
}

.ctr {
  width: auto;
  text-align: center;
  margin-bottom: 0.50%;
  margin-top: .50%;
}

.im-overlay {
  position: absolute;
  left: 0px;
  z-index: 500;
  position: relative;
}

#cropPhoto {
  width: 100%;
}

#map-box {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 10;
}

#map-view {
  width: 626px;
  height: 100%;
  float: right;
}

.map-view-top {
  width: 100%;
  background-color: #ffffff;
  box-sizing: border-box;
  margin-bottom: .5%;
  margin-top: .5%;
}

.ctr-map {
  width: 100%;
  text-align: center;
}

.btnim.active {
  color: rgb(255,255,255);
  cursor: pointer;
  text-decoration: none !important;
}

#btnChangeRoom {
}

#btnRotate {
}

.btnim {
  text-align: center;
  -moz-border-radius: 5px;
  box-sizing: border-box;
  border: 1px solid #000033;
  display: inline-block;
  width: auto;
  background-color: #000033;
  font-size: 1.5vh;
  color: rgb(255,255,255);
  cursor: pointer;
  text-transform: uppercase;
  padding: 1% 3% 1%;
}


.mapping-box {
  width: 588px;
  border: solid 2px #fff;
  background-color: #fff;
  margin: 0 auto;
}

.mapping-nav {
  width: 100%;
  height: auto;
  background-color: #fff;
  margin-top: .75%;
}

.mapping-nav-left {
  width: 57%;
  float: left;
  padding-left: .5%;
}

.mapping-nav-right {
  width: 40%;
  display: inline-block;
  float: right;
  text-align: right;
}

#im-tools {
  width: 35%;
  height: 100%;
  background-color: #dfdfdf;
  position: relative;
  z-index: 10;
}

#im-crop {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: #ffffff;
  left: 0;
  right: 0;
  top: 0;
  z-index: 22230;
}



/*VIDEO HELP DIV - WILL SHOW LATER */

.crop-fullscreen {
  width: 100%;
  border: 3px solid #999999;
  text-align: center;
  color: #FFF;
  text-decoration: underline;
  cursor: pointer;
  text-decoration: none;
  background-repeat: no-repeat;
}

.crop-fullscreen img {
  width: 100%;
  height: 100%;
}

.crop-caption {
  background-color: rgba(0, 0, 0, 0.75);
  font-size: 1.5vh;
  padding: 3% 2% 2%;
  box-sizing: border-box;
  margin-top: -2%;
}


.crop-box {
  width: 80%;
  box-sizing: border-box;
  margin: 10% auto 0 auto;
}

.crop-box h2 {
  color: #000033;
  font-size: 2.5vh;
  text-align: center;
  text-transform: uppercase;
  line-height: 6vh;
  font-weight: 700;
}

.crop-box p {
  text-align: center;
  font-size: 1.75vh;
  line-height: 2.25vh;
  color: #58585a;
  margin: 4%;
  text-align: left;
}


.perspective-fullscreen {
  background-image: url(/img/vid/perspective.gif);
  border: 3px solid #999999;
  text-align: center;
  color: #FFF;
  text-decoration: underline;
  cursor: pointer;
  text-decoration: none;
  background-repeat: no-repeat;
  margin: 0 auto;
  width: 85%;
  height: 19vw;
  position: relative;
  background-size: cover;
  -o-object-fit: cover;
  object-fit: cover;
}


.perspective-caption {
  height: 23px;
  width: 178px;
  background-image: url(/img/black.png);
  margin: 98px auto 0 auto;
  border-radius: 0 0 5px 5px;
}


.perspective-box {
  width: 200px;
  height: 425px;
  margin: 95px auto 0 auto;
}

.perspective-box h2 {
  color: #000033;
  font-size: 22px;
  font-weight: bold;
  line-height: 26pt;
  text-align: center;
  text-transform: uppercase;
}

.perspective-box p {
  text-align: left;
  font-size: 13px;
  line-height: 17px;
  padding-left: 5px;
  font-weight: 500;
  color: #58585a;
}

#im-floor {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: #dfdfdf;
  background-color: #ffffff;
  left: 0;
  right: 0;
  top: 0;
  border-radius: 0 5px 5px 0;
  z-index: 220;
  display: none;
}



.floor-area ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.floor-area ul li.active {
  color: #483698;
}


.divider {
  width: 98%;
  height: 10px;
  border-top: dashed 1px #595959;
  margin-top: 6px;
}

.step {
  text-align: center;
  color: #575757;
  font-size: 2vh;
}

.area {
  text-align: center;
  font-size: 22px;
  color: #483698;
  margin-top: 4px;
  font-weight: 900;
}



.choose-tool {
  width: 100%;
  height: 7vmin;
  margin: 0 auto;
  background-color: #fff;
  margin-bottom: 3%;
}

.choose-tool ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.choose-tool ul li.active {
  background-color: #000033;
  color: #FFF;
}

.choose-tool ul li.middle {
  width: 25%;
  border-left: solid 1px #DBDBDB;
}

.choose-tool ul li.rights {
}

.choose-tool ul li {
  float: left;
  /* height: 74px; */
  width: 25%;
  display: inline;
  background-color: #575757;
  color: #c3c3c3;
  font-size: 1.5vmin;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  padding-top: 1vmin;
  line-height: 2vmin;
  box-sizing: border-box;
  /* margin-bottom: 1vmin; */
  padding-bottom: .75vmin;
}

.choose-tool ul li i {
  font-size: 4vmin;
}

/*VIDEO HELP DIV - WILL SHOW LATER */

.tool-fullscreen {
  border: 3px solid #999999;
  text-align: center;
  color: #FFF;
  text-decoration: underline;
  cursor: pointer;
  text-decoration: none;
  background-repeat: no-repeat;
  margin: 0 auto;
  width: 85%;
  height: 19vw;
  position: relative;
}

.tool-caption {
  background-color: rgba(0, 0, 0, 0.75);
  font-size: 1.5vh;
  padding: 3% 2% 2%;
  margin-top: -2%;
  position: absolute;
  width: 100%;
  box-sizing: border-box;
  bottom: 0px;
}

.tool-text {
  font-size: 1.5vh;
  text-align: left;
  color: #58585a;
  width: 90%;
  padding-left: 10%;
  box-sizing: border-box;
  margin-top: 3%;
}

.tool-text.small {
}

.tool-text ul {
}

div.floor-tools div.tool-text ol, div.floor-tools div.tool-text p {
  display: none;
}

div.floor-tools div.tool-text ol {
  padding: 13px;
}

div.floor-tools.outline div.tool-text .useDraw {
  display: block;
}

div.floor-tools.paint div.tool-text .usePaint {
  display: block;
}

div.floor-tools.erase div.tool-text .useErase {
  display: block;
}

div.floor-tools.fill div.tool-text .useWand {
  display: block;
}


div.floor-tools.outline .tool-fullscreen {
  background-image: url(/img/vid/outline.gif);
  background-size: cover;
  -o-object-fit: cover;
  object-fit: cover;
}

div.floor-tools.paint .tool-fullscreen {
  background-image: url(/img/vid/paint.gif);
  background-size: cover;
  -o-object-fit: cover;
  object-fit: cover;
}

div.floor-tools.erase .tool-fullscreen {
  background-image: url(/img/vid/erase.gif);
  background-size: cover;
  -o-object-fit: cover;
  object-fit: cover;
}

div.floor-tools.fill .tool-fullscreen {
  background-image: url(/img/vid/Fill.gif);
  background-size: cover;
  -o-object-fit: cover;
  object-fit: cover;
}


.tool-text li {
  margin-bottom: 10px;
  color: #58585a;
  font-size: 1.5vh;
}

div.floor-tools div.tool-text ol {
  padding: 13px;
}

.tool-sc {
  width: 100%;
}

#im-angle {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: #ffffff;
  left: 0;
  right: 0;
  top: 0;
  border-radius: 0 5px 5px 0;
  z-index: 210;
  display: none;
}

.floor-area {
  width: 100%;
}

.floor-area ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.floor-area ul li.selected {
  color: #999;
}

.floor-area ul li.active {
  color: #008AFF;
}

.floor-area ul li {
  font-size: 1.75vh;
  cursor: pointer;
  display: inline-block;
  color: #999;
  text-align: center;
  cursor: pointer;
  font-weight: 700;
  padding: 1% 5%;
  width: 48%;
}

.area {
  text-align: center;
  font-size: 2vh;
  color: #000033;
  text-transform: Uppercase;
}

.floor-tools {
  width: 85%;
  border: solid 2px #C8C8C8;
  background-color: #fff;
  margin-top: 1%;
  margin-left: 7%;
  overflow-y: auto;
}

[class^=slider] {
  display: inline-block;
  margin-bottom: 15px;
}

.width {
  width: 13%;
  float: left;
  background-color: #FFF;
  font-weight: bold;
  font-size: 1.25vh;
  line-height: 2vh;
  margin-left: 3%;
}

[class^=slider] {
  display: inline-block;
  margin-bottom: 15px;
}

.slidercontain {
  background-color: #ffffff;
  width: 90%;
  margin-left: 3%;
  margin-bottom: 0 !important;
  height: 20px !important;
}

.slider-center {
  width: 50%;
  height: 20px;
  background-color: #FFF;
  float: left;
}

.slider-volume {
  width: 60% !important;
}

.slider-center input.value {
  position: relative;
  right: 2.5%;
  float: right;
  text-align: center;
  font-size: 1.25vmin;
}

.feet {
  /*float: left;*/
  font-weight: bold;
  font-size: 1.5vh;
  line-height: 2vh;
  color: #888;
  padding-left: 0;
}

.tool-ss {
  height: 50px;
  width: 100%;
  margin: 2% auto 0 auto;
}

#overlay {
  position: fixed;
  top: 0;
  left: 0;
  background-color: #000;
  width: 100%;
  height: 100%;
  display: none;
  position: absolute;
  z-index: 997;
}

#modPop {
  width: 30%;
  background-color: #FFF;
  border-top: solid 9px #000033;
  position: absolute;
  padding: 3vmin;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 999;
  box-shadow: 0px 6px 15px 0px rgba(50, 50, 50, 0.5);
  overflow: hidden;
  overflow-y: auto;
  display: none;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

#modWarning {
  background-color: #FFF;
  border-top: solid 9px #000033;
  position: absolute;
  padding: 2vmin;
  z-index: 999;
  box-shadow: 0px 6px 15px 0px rgba(50, 50, 50, 0.5);
  overflow: hidden;
  overflow-y: auto;
  display: none;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

#modWarning-headline {
  color: #000033;
  text-align: center;
  font-size: 2.5vmin;
}


#modWarning-text {
  font-size: 1.75vmin;
  text-align: center;
  margin-top: 5px;
  margin-bottom: 15px;
}

.modWarning-button {
  cursor: pointer;
  padding: 1vmin 2vmin;
  background-color: #fff;
  margin: 2vmin;
  border: solid 2px #000033;
  text-align: center;
  color: #000033;
  line-height: 2vmin;
  /* padding-top: 4px; */
  font-size: 2vmin;
}

.modWarning-button:hover {
  background-color: #585858;
  border: solid 2px #585858;
  text-align: center;
  color: #fff;
}

#modPop-headline {
  text-align: left;
  font-weight: bold;
  color: #000033;
  float: left;
  font-size: 2.5vmin;
}


#modPop-newsurfacelist {
  line-height: 6vmin;
  color: #000;
  margin-bottom: 3vmin;
  font-size: 2vmin;
}

.modPop-close {
  height: 25px;
  width: 50px;
  color: #9d9d9d;
  cursor: pointer;
  position: absolute;
  top: 10px;
  left: 402px;
  display: none;
}



.btn-modPop {
  color: #ffffff;
  font-size: 1.5vmin;
  border: solid #fff 1px;
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  width: auto;
  margin: 0 5px 0 1px;
  text-transform: uppercase;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJod…EiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+');
  line-height: 3vmin;
  background-color: #000033;
  padding: .5vmin 1vmin;
}

.btn-modPop.dark {
  background: #575757;
  color: #fff;
}

.btn-modPop.grey {
  background: #595959;
  color: #fff;
}

.mp {
  color: #000;
  font-size: 16px;
  line-height: 50px;
  font-weight: 900;
  display: inline-block;
  margin-right: 7px;
  vertical-align: middle;
}

.mp.grey {
  color: #999;
}

.modPop-rule-sm {
  border-bottom: 2px solid #000;
  width: 276px;
  margin: 19px auto;
}

.btnsave-purple {
  font-size: 14px;
  padding: 10px 13px;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  background-color: #fff;
  color: #FFF;
  text-align: center;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  box-sizing: border-box;
  background-color: #000033;
  margin-top: 7px;
  /*min-width: 230px;*/
  text-transform: uppercase;
  border-radius: 0px;
  box-sizing: border-box;
  font-size: 1.75vh;
  padding: 3% 5%;
  width: 85.5%;
  margin-left: 7%;
}


.btnsave-purple.active {
  cursor: pointer;
  opacity: 1;
  filter: alpha(opacity=100);
  margin-right: 10px;
  background-color: #000033;
  color: #fff;
  text-transform: uppercase;
  border-radius: 0px;
  box-sizing: border-box;
  font-size: 1.75vh;
  padding: 3% 5%;
  width: 85.5%;
  margin-left: 7%;
}

.btnsave-purple.active.green {
  background-color: #2A6496;
}


.btnsave-purple.viewing {
}



.btnsave-grey {
  background-color: #2A6496;
  color: #fff;
  font-size: 14px;
  padding: 10px 26px;
  text-decoration: none;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  text-transform: uppercase;
  box-sizing: border-box;
  font-size: 1.75vh;
  padding: 3% 5%;
  width: 85.5%;
  margin-left: 7%;
}




.btnim-purple {
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  text-align: center;
  box-sizing: border-box;
  color: #fff;
  display: inline-block;
  width: auto;
  background-color: #000033;
  text-transform: uppercase;
  box-sizing: border-box;
  font-size: 1.75vh;
  padding: 3% 5%;
  width: 100%;
}

.btnmapping {
  color: #666666;
  display: inline-block;
  margin-right: 2%;
  cursor: pointer;
  text-align: right;
  font-size: 1.75vh;
}

.output {
  color: #000;
  font-size: 14px;
  font-weight: bold;
  padding-top: 1px;
  margin-left: 5px;
  vertical-align: top;
  border: solid 1px #999999;
  border-radius: 5px;
  padding: 4px;
}

.currentSurfaceTitle {
  display: none;
}


tool-fullscreen {
  height: 119px;
  width: 141px;
  background-image: url(/img/fullscreen.jpg);
  margin: 10px 0 10px 70px;
  border-radius: 5px;
  border: 1px solid #483698;
  text-align: center;
  color: #ffffff;
  font-weight: bold;
  text-decoration: underline;
  line-height: 23px;
  cursor: pointer;
}

.vid-modal {
  background-color: #FFF;
  border-radius: 14px;
  z-index: 1100;
  display: none;
  box-shadow: 0px 6px 15px 0px rgba(50, 50, 50, 0.5);
  background-repeat: no-repeat;
  background-repeat: repeat-x;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  padding: 2vmin;
}

.vid-modal.outline {
  display: none;
}

.vid-modal.paint {
  display: none;
}

.vid-modal.wand {
  display: none;
}

.vid-modal.erase {
  display: none;
}

.vid-modal.perspective {
  display: none;
}


.vid-modal-headline {
  /* width: 525px; */
  /* margin-top: 30px; */
  /* margin-left: 26px; */
  text-align: center;
  /* padding-left: 82px; */
  font-size: 2.5vmin;
  text-transform: uppercase;
  font-weight: 700;
  color: #000033;
}

.vid-modal-close {
  color: #000033;
  cursor: pointer;
  font-size: 3vmin;
  position: absolute;
  right: 2vmin;
  top: 1vmin;
}

.vid-modal-box {
  width: 78vmin;
  height: 52vmin;
  margin: 21px auto;
  text-align: center;
  display: block;
  background-image: url(/img/vid/Crop.gif);
  background-size: cover;
  -o-object-fit: cover;
  object-fit: cover;
}

.vid-modal-box.outline {
  background-image: url(/img/vid/outline.gif);
  background-size: cover;
  -o-object-fit: cover;
  object-fit: cover;
}

.vid-modal-box.paint {
  background-image: url(/img/vid/paint.gif);
  background-size: cover;
  -o-object-fit: cover;
  object-fit: cover;
}

.vid-modal-box.wand {
  background-image: url(/img/vid/fill.gif);
  background-size: cover;
  -o-object-fit: cover;
  object-fit: cover;
}

.vid-modal-box.erase {
  background-image: url(/img/vid/erase.gif);
  background-size: cover;
  -o-object-fit: cover;
  object-fit: cover;
}

.vid-modal-box.perspective {
  background-image: url(/img/vid/perspective.gif);
  background-size: cover;
  -o-object-fit: cover;
  object-fit: cover;
}


.vid-text {
  color: #58585a;
  font-weight: bold;
  display: block;
  text-align: center;
  font-size: 1.5vmin;
}

/*overlay*/

#vid-overlay {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.76);
  width: 100%;
  height: 100%;
  display: none;
  position: absolute;
  z-index: 1099;
}



/* ChamModal  */
.chamModal {
  background: url("/ChamWebTools/JS/ChamModal/1/overlay.png") repeat left top;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  display: none;
  z-index: 999;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: 0;
  padding: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  -moz-user-drag: none;
  user-drag: none;
}

.chamModal .modalInner {
  position: absolute;
  /*min-width: 25%;*/
  background: #e5e5e5;
  border: 2px solid #cfcfcf;
  left: 50%;
  top: 50%;
  -ms-transform: translate(-50%,-50%); /* IE 9 */
  -webkit-transform: translate(-50%,-50%); /* Safari */
  transform: translate(-50%,-50%);
}

.chamModal .modalInner.Draggable {
  vertical-align: top;
  margin: 0 auto 0 auto;
  max-width: 100%;
  max-height: 100%;
  /*min-width: 25%;*/
  background: #e5e5e5;
  border: 2px solid #cfcfcf;
  margin-left: auto;
  margin-right: auto;
  color: #555;
  left: 50%;
  top: 30%;
  -ms-transform: translate(-50%,-50%); /* IE 9 */
  -webkit-transform: translate(-50%,-50%); /* Safari */
  transform: translate(-50%,-50%);
  border-radius: 5px;
}

.chamModal .modalTitleBar {
  width: 100%;
  height: 40px;
  line-height: 40px;
  border: none;
  background: none;
  text-align: right;
  z-index: 20;
}

.chamModal .modalTitleBar .title, .chamModal .modalTitleBar .closeBTN {
  display: inline-block;
  zoom: 1;
  *display: inline;
  vertical-align: top;
  position: relative;
  color: #fff;
  padding: 0;
  margin: 0;
}

.chamModal .modalTitleBar .title {
  font-size: 20px;
  text-align: left;
  width: auto;
  float: left;
  text-indent: 10px;
}

.chamModal .modalTitleBar .closeBTN {
  font-size: 30px !important;
  /* right: 6px; */
  cursor: pointer;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  color: #999;
  top: -2px;
}

.chamModal .modalContent {
  padding: 10px;
}


.chamModal .modalContent .button {
  cursor: pointer;
}

.chamModal .modalContent .question {
  margin-bottom: 12px;
  font-size: 14px;
}

.rightPadding {
  float: right;
  padding-top: 2px;
}

.newSurface {
  font-size: 1.5vmin;
  color: #666666;
  text-transform: none !important;
}


.gone {
  font-size: 3vmin;
  position: absolute;
  right: 15px;
  top: 10px;
  cursor: pointer;
}



.goright i {
  padding-left: 6px;
  font-size: 1.75vh;
}


.upload-box-left {
  height: 412px;
  width: 280px;
  padding: 75px 0 0 75px;
  background-color: #fff;
  float: left;
}


#panTip {
  width: 100%;
  height: auto;
  display: block;
  padding: .5%;
  color: #575757;
  font-size: 1.35vh;
  box-sizing: border-box;
  margin-top: .75%;
}

.panIcon {
  float: left;
  padding-right: 1%;
}

.panIcon i {
  font-size: 4vh;
  color: #575757;
}


.widthSelect {
  position: absolute;
  width: 140px !important;
  height: 148px;
  z-index: 999;
  border: 1px solid #fff;
  display: none;
  background-color: #383b43;
  margin-top: 7px;
  margin-left: 0px;
  clear: both;
}

.widthSelect li {
  height: 49px !important;
  padding: 0px !important;
  clear: both;
}


.slider-box {
  margin: 0 auto !important;
  width: 100%;
  padding-left: 6%;
  box-sizing: border-box;
}


/*THIS WILL MAKE IM IMAGE RESPONSIVE */

.im-box {
  width: 100% !important;
}

#im-view, .im-overlay {
  width: 100% !important;
}

#map-view {
  width: 62% !important;
  margin-right: 2%;
}

#mapping-box {
  width: 100% !important;
}


#im-view {
  width: 100% !important;
  overflow-y: auto;
  overflow-x: hidden;
}

.imap, #im-tools {
  width: 35% !important;
}

#viz, #viz .ImageMapper, #viz .ImageMapper canvas {
  width: 100% !important;
}
/* , #viz .ImageMapper canvas */


/*END THIS WILL MAKE IM IMAGE RESPONSIVE */

@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (orientation : landscape) {
  /* iPhone 6+ landscape */



  .tool-text {
    font-size: 2.1vh;
    line-height: 2.2vh;
    margin-top: 2%;
  }

  .tool-text li {
    font-size: 1.75vh;
    margin-bottom: 2px;
  }



  div.floor-tools div.tool-text ol {
    padding: 0px;
  }


  #panTip {
    font-size: 1.85vh;
  }

  .panIcon i {
    font-size: 7vh;
  }

  .slider-center {
    /*width: 73%;*/
  }

  .perspective-fullscreen {
    margin-top: -6%;
  }

  .slider-center {
    /*width: 69%;*/
  }


  .slider-center input.value {
    /*top: -39px !important;*/
  }

  .perspective-fullscreen {
    width: 44%;
    height: 9vw;
  }
}
/* iPhone 6 landscape */
@media only screen and (min-device-width : 375px) and (max-device-width : 667px) and (orientation : landscape) {

  .slider-center input.value {
    /*top: -35px !important;*/
  }

  .perspective-fullscreen {
    width: 44%;
    height: 9vw;
  }
}




/* ipad in landscape */
@media only screen and (min-device-width : 961px) and (max-device-width : 1024px) and (orientation : landscape) {

  .slider-center {
    /* width: 73%; */
  }
}

/* 7" Tablet */
@media only screen and (min-device-width : 600px) and (max-device-width : 960px) and (orientation : landscape) {

  .slider-center {
    /*width: 73%;*/
  }

  .width {
    font-size: 1.25vh;
    line-height: 3.5vh;
  }

  .slider-center input.value {
    /*position: relative;
    top: 0px;
    right: 1.5%;
    float: right;
    text-align: center;
    font-size: 1.5vh;*/
  }

  .feet {
    line-height: 3vh;
  }
}


/* Galaxy 10 Landscape */
@media only screen and (min-device-width : 1025px) and (max-device-width: 1280px) and (orientation: landscape) {



  .slider-box {
    /*padding-left: 3%;*/
  }


  .slider-center {
    /*width: 73%;*/
  }
}


/* iPhone 5 Portrait */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {

  .feet {
    padding-left: 0;
    float: right;
  }

  .slider-center {
    width: 75%;
    height: 20px;
    background-color: #FFF;
    float: left;
  }
}

/* iPhone 6 Portrait */
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {


  .feet {
    padding-left: 37%;
  }
}

/* iphone 6+ Portrait */
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {


  .feet {
    padding-left: 37%;
  }
}

/* MISC STYLES
   ========================================================================== */


.clearfix {
  display: inline-block;
}

.clearfix:after {
  content: " ";
  display: block;
  height: 0;
  clear: both;
  overflow: hidden;
  visibility: hidden;
}

.clearfix {
  display: block;
}


/* Simple Slider
   ========================================================================== */

.slider {
  width: 300px;
}

.slider > .dragger {
  background: #8DCA09;
  background: -webkit-linear-gradient(top, #8DCA09, #72A307);
  background: -moz-linear-gradient(top, #8DCA09, #72A307);
  background: linear-gradient(top, #8DCA09, #72A307);

  -webkit-box-shadow: inset 0 2px 2px rgba(255,255,255,0.5), 0 2px 8px rgba(0,0,0,0.2);
  -moz-box-shadow: inset 0 2px 2px rgba(255,255,255,0.5), 0 2px 8px rgba(0,0,0,0.2);
  box-shadow: inset 0 2px 2px rgba(255,255,255,0.5), 0 2px 8px rgba(0,0,0,0.2);

  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;

  border: 1px solid #496805;
  width: 16px;
  height: 16px;
}

.slider > .dragger:hover {
  background: -webkit-linear-gradient(top, #8DCA09, #8DCA09);
}


.slider > .track, .slider > .highlight-track {
  background: #ccc;
  background: -webkit-linear-gradient(top, #bbb, #ddd);
  background: -moz-linear-gradient(top, #bbb, #ddd);
  background: linear-gradient(top, #bbb, #ddd);

  -webkit-box-shadow: inset 0 2px 4px rgba(0,0,0,0.1);
  -moz-box-shadow: inset 0 2px 4px rgba(0,0,0,0.1);
  box-shadow: inset 0 2px 4px rgba(0,0,0,0.1);

  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;

  border: 1px solid #aaa;
  height: 4px;
}

.slider > .highlight-track {
  background-color: #8DCA09;
  background: -webkit-linear-gradient(top, #8DCA09, #72A307);
  background: -moz-linear-gradient(top, #8DCA09, #72A307);
  background: linear-gradient(top, #8DCA09, #72A307);

  border-color: #496805;
}

// VOLUME

.slider-volume {
  width: 80%;
}

.slider-volume > .dragger {
  width: 18px;
  height: 18px;
  margin: 0 auto;
  border: 1px solid rgba(255,255,255,0.6);
  /*-moz-box-shadow: 0 0px 2px 1px rgba(0,0,0,0.5), 0 2px 5px 2px rgba(0,0,0,0.2);
  -webkit-box-shadow: 0 0px 2px 1px rgba(0,0,0,0.5), 0 2px 5px 2px rgba(0,0,0,0.2);
  box-shadow: 0 0px 2px 1px rgba(0,0,0,0.5), 0 2px 5px 2px rgba(0,0,0,0.2);*/
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  background: #8F0100;
  /*background: -moz-linear-gradient(90deg, rgba(180,180,180,1) 20%, rgba(230,230,230,1) 50%, rgba(180,180,180,1) 80%);
  background:	-webkit-radial-gradient(  50%   0%,  12% 50%, hsla(0,0%,100%,1) 0%, hsla(0,0%,100%,0) 100%),
              -webkit-radial-gradient(  50% 100%, 12% 50%, hsla(0,0%,100%,.6) 0%, hsla(0,0%,100%,0) 100%),
              -webkit-radial-gradient(	50% 50%, 200% 50%, hsla(0,0%,90%,1) 5%, hsla(0,0%,85%,1) 30%, hsla(0,0%,60%,1) 100%);*/
}

.slider-volume > .track, .slider-volume > .highlight-track {
  height: 8px;
  background: #FFFFFF;
  border:1px solid #999999;
  /*-moz-box-shadow: inset 0 2px 5px 1px rgba(0,0,0,0.15), 0 1px 0px 0px rgba(230,230,230,0.9), inset 0 0 1px 1px rgba(0,0,0,0.2);
  -webkit-box-shadow: inset 0 2px 5px 1px rgba(0,0,0,0.15), 0 1px 0px 0px rgba(230,230,230,0.9), inset 0 0 1px 1px rgba(0,0,0,0.2);
  box-shadow: inset 0 2px 5px 1px rgba(0,0,0,0.15), 0 1px 0px 0px rgba(230,230,230,0.9), inset 0 0 1px 1px rgba(0,0,0,0.2);*/
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

.slider-volume > .highlight-track {
  background-color: #c5c5c5;
  background: -moz-linear-gradient(top, #c5c5c5, #a2a2a2);
  background: -webkit-linear-gradient(top, #c5c5c5, #a2a2a2);
  background: linear-gradient(top, #c5c5c5, #a2a2a2);
}
