﻿@charset "utf-8";

@-ms-viewport {
  width: device-width;
}


@font-face {
  font-family: 'FuturaBT';
  src: url('/css/webfonts/FuturaBT-Medium.eot?#iefix') format('embedded-opentype'),  url('/css/webfonts/FuturaBT-Medium.woff') format('woff'), url('/css/webfonts/FuturaBT-Medium.ttf')  format('truetype'), url('/css/webfonts/FuturaBT-Medium.svg#FuturaBT-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'FuturaT';
  src: url('/css/webfonts/FuturaTBold.eot?#iefix') format('embedded-opentype'),  url('/css/webfonts/FuturaTBold.woff') format('woff'), url('/css/webfonts/FuturaTBold.ttf')  format('truetype'), url('/css/webfonts/FuturaTBold.svg#FuturaTBold') format('svg');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'ProximaNova-Regular';
  src: url('/css/webfonts/ProximaNova-Regular.eot?#iefix') format('embedded-opentype'),  url('/css/webfonts/ProximaNova-Regular.otf')  format('opentype'),
	     url('/css/webfonts/ProximaNova-Regular.woff') format('woff'), url('/css/webfonts/ProximaNova-Regular.ttf')  format('truetype'), url('/css/webfonts/ProximaNova-Regular.svg#ProximaNova-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}


*, *:before, *:after {
  box-sizing: inherit;
}

.cham {
  color: #242424;
  font-size: 1em;
  line-height: 1.4;
  font-family: 'ProximaNova-Regular', Arial, serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


/* Remove text-shadow in selection highlight */

::-moz-selection {
  background: #b3d4fc;
  text-shadow: none;
}

::selection {
  background: #b3d4fc;
  text-shadow: none;
}


/* A better looking default horizontal rule */

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}


/* Remove the gap between element and the bottom of their containers */

audio, canvas, iframe, img, svg, video {
  vertical-align: middle;
}


/* Remove default fieldset styles. */

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

/* Allow only vertical resizing of textareas. */

textarea {
  resize: vertical;
}