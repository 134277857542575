/* ----------------------------------------------------------------
Loading Graphic
-----------------------------------------------------------------*/

.loading-msg {
  position: absolute;
  top: 50% !important;
  left: 50% !important;
  text-align: center;
  transform: translate(-50%, -50%);
}

.loading-msg-inner {
	color: white;
	display: none;
}

@keyframes uil-ellipsis {
  0% {
    transform: scale(0);
    left: 0px;
    opacity: 1;
  }

  12.5% {
    transform: scale(1);
  }

  25% {
    left: 0px;
  }

  37.5% {
    left: 70px;
  }

  50% {
    left: 70px;
  }

  62.5% {
    left: 140px;
  }

  75% {
    left: 140px;
    transform: scale(1);
  }

  87.5% {
    left: 140px;
    transform: scale(0);
    opacity: 1;
  }

  100% {
    left: 140px;
    opacity: 0;
  }
}

.uil-ellipsis-css {
  background: none;
  position: relative;
  width: 200px;
  height: 200px;
}

.uil-ellipsis-css .ib {
  width: 100%;
  height: 100%;
  transform: rotate(0deg);
}

.uil-ellipsis-css .circle {
  width: 60px;
  height: 60px;
  position: absolute;
  top: 70px;
  opacity: 0;
  text-align: center;
  animation: uil-ellipsis 1s linear infinite;
}

.uil-ellipsis-css .circle > div {
  width: 60px;
  height: 60px;
  border-radius: 30px;
  margin: 0px;
}

.uil-ellipsis-css .circle:nth-of-type(2n+1) > div {
  background: #fff;
}

.uil-ellipsis-css.dark .circle:nth-of-type(2n+1) > div {
  background: #403d3d !important;
}

.uil-ellipsis-css .circle:nth-of-type(2n) > div {
  background: #EE2E24;
}

.circle:nth-of-type(1) {
  animation-delay: 0s;
}

.circle:nth-of-type(2) {
  animation-delay: 0.25s;
}

.circle:nth-of-type(3) {
  animation-delay: 0.5s;
}

.circle:nth-of-type(4) {
  animation-delay: 0.75s;
}