﻿/* ----------------------------------------------------------------
Scenes
-----------------------------------------------------------------*/

section.scenes {
    display: none;
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;

    > div {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    h2 {
        text-align: center;
        font-weight: 500;
        font-size: 3.5vmin;
        padding: 2vmin 0 1.5vmin;
        font-family: 'FuturaBT', Arial, serif;
    }
}

.scene-wrapper {
    width: 27%;
    padding: 0;
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
}

#roomscene-data {
    padding: 0;
    margin: 0;
    list-style-type: none;
    display: flex;
    flex-flow: row wrap;
}

#roomscene-data li {
    flex: 0 1 43%;
    /*margin: 0 0 20px 0;*/
    margin: 5px;
    padding: 0;
    font-size: 2vmin;
    border: 2px solid transparent;
    cursor: pointer;

    &:hover, &.active {
        border-color: #EE2E24;
    }

    &.active {
        cursor: default;
    }
}


#roomscene-data img {
    width: 100%;
    height: 15vw;
}

#roomscene-data img[src] {
    width: 100%;
    height: auto;
    background-image: none;
}

#select-scene-section {
    min-height: 200px;
    display: flex;
    flex-flow: row wrap;
    flex-grow: 1;
    justify-content: space-around;
}

#big-scene-wrapper {
    width: 55%;
    position: relative;

    .loading-msg {
        top: 0 !important;
        transform: translateX(-50%);
    }
}


#bb-box {
    display: flex;
    flex-flow: column nowrap;
    height: 100%;
}

#big-scene {
    width: 100%;
    height: 80%;
    text-align: center;
    margin: 0 auto;
    position: relative;
    flex-grow: 2;

    img {
        width: 100%;
        object-fit: contain;
    }
}

/*#scenes-buttons {
	padding: 30px 0;
    display:flex;
    flex-flow:row wrap;
    justify-content:center;
	align-items: flex-start;
}*/

#scenes-buttons {
    padding: 30px 0;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    width: 100%;
    align-items: flex-start;
}

#landing-projects {
    border: solid 1px #ee2e24;
    color: #ee2e24;
    padding: 2.6vmin 9vmin;
    font-size: 1.2em;
    font-family: 'FuturaT', Arial, serif;
    font-weight: bold;
    margin-right: 3vmin;
    letter-spacing: 1px;
    line-height: 1;
    text-transform: uppercase;
}

#get-started img {
    margin-left: 6vmin;
}

.margin-bottom-30 {
    margin-bottom: 30px;
}

@include mobile-breakpoint() {
    section.scenes > div {
        display: block;
    }

    section.scenes h2 {
        font-size: 2em;
    }

    #select-scene-section {
        padding: 0px 9vmin;
        display: block;
    }

    #roomscene-data li {
        flex: 0 0 29%;
    }

    .scene-wrapper {
        width: 100%;
        margin: 4vmin 0 5vmin;
    }

    #scenes-buttons {
        padding-bottom: 0;
    }

    #big-scene-wrapper {
        width: 100%;
        min-height: 200px;
    }

    #big-scene {
        height: auto;
        position: relative;
    }

    #landing-projects {
        margin-bottom: 8vmin;
        padding-top: 4vmin;
        padding-bottom: 4vmin;
    }

    #roomscene-data li {
        margin: 1%;
    }
}





