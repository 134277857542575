#share-options {
	width: 100%;
    padding: 0 2vmin 3vmin; 
	background-color: #ffffff;
	position: absolute;
	top: 0;
	left: 0;
    z-index: 999;
    transform: translateY(-100%);
	transition: .3s transform ease-out;
	z-index: 1501;
	-webkit-tap-highlight-color: transparent;

	&.active {
		transform: none;
	}
}

#share-options p{
    width:75%;
    margin:0 auto;
    text-align:center;
    clear:both;
}

#share-options-inner{
    width:75%;
    margin:2.5vmin auto 0;
    display:flex;
    flex-flow:row wrap;
    justify-content:space-around;
}

#share-platforms{
    display:flex;
    flex-flow:row wrap;
    justify-content:space-around;
	align-content: space-around;
}

.share-stuff {
    width:48%;
    display:flex;
    flex-flow: column wrap;
    align-items:center;
    cursor:pointer;
    margin: 2vmin 0;
	font-size: 0.8em;
	color: inherit;
	text-decoration: none;

	img {
		width:40px;
	}
}

.share-stuff i.fa {
    font-size: 4.2vmin;
}

@include mobile-breakpoint() {
	#share-options {
		height: 100%;
		padding: 4vmin;
		position: fixed; /* Scrolling and positioning bugs occuring on iPad when using position: absolute; */
		transform: translateY(100%);
		overflow-x: hidden;
		overflow-y: auto;
		-webkit-overflow-scrolling: touch;
		z-index: 1500;
		
		p {
			padding-top: 4vmin;
		}
	}

	#share-platforms {
		margin-top: 5vmin;
	}

	.share-stuff {
		font-size: 1em;

		i.fa {
			font-size: 2.6em;
		}
	}

	#share-image {
		max-width: 100%;
	}
}