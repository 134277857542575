/*----------------------------------------------------------------
Message Overlays
-----------------------------------------------------------------*/

.message-overlay {
  width: 100%;
  height: 100%;
  background-color: rgba(35, 93, 123, 0.95);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10000;
  display: none;
}

.message-overlay img {
  width: 100%;
  max-width: 600px;
  max-height: 100%;
  display: inline-block;
  vertical-align: middle;
}
